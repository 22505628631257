/* eslint-disable jsx-a11y/alt-text */
import { React, useRef, useState } from "react";
// import { useRadioGroup } from "@mui/material/RadioGroup";
import Line from "./../../Assets/Quiz/Line.svg";
import Download from "./../../Assets/Quiz/Download.svg";
import Share from "./../../Assets/Quiz/Share.svg";
import QR from "./../../Assets/Quiz/qrcode.svg";
import upload from "./../../Assets/Quiz/upload.svg";
import Create from "./../../Assets/Quiz/Create PD Button.svg";
import Pdf from "./../../Assets/Quiz/PDF.svg";
import searchIcon from "./../../Assets/UserIcon/usersearchicon.svg";
import down from "./../../Assets/EnquiresIcon/down.svg";
import filter from "./../../Assets/EnquiresIcon/filter.svg";
import filteron from "./../../Assets/EnquiresIcon/filteron.svg";
import green_down from "./../../Assets/EnquiresIcon/green_down.svg";
import Document from "./../../Assets/Quiz/Document.svg";
import Delete from "./../../Assets/Quiz/Delete.svg";
import _http from "../../Utils/Api/_http";
import "./Quiz.scss";
import { Popover, Space, Modal } from "antd";
import Button1 from "./../../Components/Button/Button";
// import QuizDrag from "./QuizDrag";
import { rem } from "../../Components/Rem_func";
import SnackBar from "../../Components/SnackBar";

const Quiz = () => {
  // Define the state to hold the content
  const [content, setContent] = useState("Click to edit...");
  const [file, setFile] = useState();
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");
  // Function to handle content change
  const handleInput = (e) => {
    setContent(e.target.innerText); // Capture the editable text
  };
  const fileInputRef = useRef(null);

  //model
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
  };
  //Drag
  // State to hold the uploaded files
  const [files, setFiles] = useState([]);
  const [isDropped, setIsDropped] = useState(false); // Track if file has been dropped

  //Filter & Search - Modal
  const [filterOn, setFilterOn] = useState(false);

  //Event for the Upload and recent buttons
  const [clicked, setClicked] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const [isUpload, setUpload] = useState(false);

  function Upload(id) {
    setActiveButton(id);
    alert("clicked");
    setUpload(true);
    setClicked(true);
    if (id === 1) {
      setClicked((prev) => !prev);
    } else {
      setClicked((prev) => prev);
    }
    // if (isDropped) {
    // } else {
    //   alert("Upload Files");
    // }
  }
  function Recent(id) {
    setActiveButton(id);
    if (id === 2) {
      setClicked((prev) => !prev);
    } else {
      setClicked((prev) => prev);
    }
    setUpload(false);
  }
  //input event of the Quiz Upload
  const [inputValue, setInputValue] = useState("");
  const [inputValue1, setInputValue1] = useState("");
  const isbuttondisabled = inputValue1.length !== 2;
  function numberQuestion1(e) {
    const value1 = e.target.value;

    // Allow only digits in the input field
    if (/^\d{0,2}$/.test(value1)) {
      setInputValue1(value1);
    }
  }
  function numberQuestion2(e) {
    const value = e.target.value;
    // Allow only digits in the input field
    if (/^\d{0,2}$/.test(value)) {
      setInputValue(value);
    }
  }
  //handle duration button
  const [clickDur, setDur] = useState(false);
  function handleDur() {
    setDur(true);
  }
  //handle duration button
  const [clickQR, setQR] = useState(false);
  function handleQR() {
    setQR(true);
  }

  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };
  const uploadPdf = async (file) => {
    setFile(file);
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
      // setIsDropped(false);
    } else {
      setFiles(true);
      // setIsDropped(true);

      try {
        const formData = new FormData();
        formData.append("file", file[0]);
        const response = await _http.post("/FileUpload_QA", formData, {
          headers: {
            // "Content-Type": "multipart/form-data",
          },
        });
        setFile();
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
      setFiles(false);
    }
  };
  return (
    <>
      <section className="quizz">
        <div className="q-up">
          {!clicked && !isUpload && (
            <>
              <div className="q-conf">
                <span className="b-btn">Configure QR Event</span>
              </div>
              <button className="q-btn">Quiz</button>
              <span className="q-btns">
                <button className="q-btn1">Catalogue</button>
                <button className="q-btn1">Photo</button>
              </span>
            </>
          )}
          {!clicked ? (
            <div className="q-up1">
              {!isUpload && (
                <div className="q-btn-mode">
                  <button
                    className={activeButton === 1 ? "active" : " "}
                    onClick={() => {
                      Upload(1);
                    }}
                  >
                    Upload Questions
                  </button>
                  <button
                    className={activeButton === 2 ? "active" : " "}
                    onClick={() => {
                      Recent(2);
                    }}
                  >
                    Recent
                  </button>
                </div>
              )}

              {isUpload ? (
                <div className="q-upload">
                    <img src={Create} alt="" width={30} />
                  <div className="q-create">
                  <div className="container">
                  </div>
                </div>
                </div>
              ) : (
                <div className="container">
                  <div
                    className={`q-upload`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDrop={handleDrop}
                    onClick={handleFileUpload}
                  >
                    <div className="drag1">
                      <img src={upload} alt="up-svg" width={20} />
                      <p>Click to Upload or drag and drop your files</p>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      uploadPdf(e.target.files);
                    }}
                  />
                </div>
              )}

              {/* Show file names after dropping
                    {files.length > 0 && (
                      <div style={{ marginTop: "20px" }}>
                        <h4>Uploaded Files</h4>
                        <ul>
                          {files.map((file) => (
                            <li key={file.name}>
                              <p>{file.name}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )} */}
              {files.length > 0 && (
                <div
                  className="q-up-data"
                  style={{
                    border: "2px solid blue",
                  }}
                >
                  <div className="uplcontainer">
                    {files.map((file) => (
                      <div key={file.id} className="uploaddetails">
                        <div className="uploaddata">
                          <h1>
                            {file.name.slice(0, -4)}
                            {clickDur && files.length >= 2 ? (
                              <p></p>
                            ) : (
                              <p>{file.path}</p>
                            )}
                            {(!clickDur && files.length) >= 2 ? (
                              <div>
                                <p>
                                  <img src={QR} alt="" />
                                </p>
                              </div>
                            ) : (
                              <p></p>
                            )}
                          </h1>
                        </div>
                        <div className="q-btn">
                          {clickDur ? (
                            <div className="q-upload-para">
                              <h1>
                                Duration
                                <span>
                                  <input
                                    type="text"
                                    placeholder="00"
                                    required
                                    onChange={numberQuestion1}
                                    maxLength={2}
                                  />
                                  mins
                                </span>
                              </h1>
                              <h1>
                                No of questions
                                <input
                                  type="text"
                                  placeholder="10"
                                  required
                                  onChange={numberQuestion2}
                                  maxLength={2}
                                />
                              </h1>
                            </div>
                          ) : (
                            <Button1
                              btnname="Change"
                              className="q-ch"
                              border={`${rem(3)} solid #cdd3d866`}
                              bg="#fff"
                              cl="#242634"
                              br={40}
                            />
                          )}

                          <img src={Delete} alt="" width={20} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {clickQR ? (
                <div className="qr">
                  <div className="qr-details">
                    <div className="qr-data">
                      <div className="q-qrimg">
                        <img src={QR} alt="" />
                        <div className="qrother">
                          <img src={Share} alt="shareimg" />
                          <img src={Download} alt="downloadimg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="qrbtn" id="qrbtn">
                    <input
                      type="datetime-local"
                      id="qrbtn"
                      placeholder="2017-06-01T08:30"
                      className="custom-datetime-input"
                    />
                    <Button1 btnname="Submit" height={40} width={100} br={12} />
                  </div>
                </div>
              ) : (
                <div className="q-duration">
                  <Button1
                    className="q-dur"
                    border={
                      isbuttondisabled
                        ? `${rem(1)} solid #000`
                        : `${rem(1)} solid #cdd3d866`
                    }
                    btnname={
                      clickDur ? "Generate QR Code" : "Update Quiz Duration"
                    }
                    onclick={clickDur ? handleQR : handleDur}
                    width={200}
                    height={50}
                    cl={
                      clickDur ? (isbuttondisabled ? "#000" : "#fff") : "#fff"
                    }
                    bg={
                      clickDur
                        ? isbuttondisabled
                          ? "#F9F9FF"
                          : "#1e6641"
                        : "#1e6641"
                    }
                    disabled={clickDur ? isbuttondisabled : false}
                  />
                </div>
              )}
            </div>
          ) : (
            // Recent Files

            <section className="up-31">
              {/* {isUpload ? <div>Hello</div> : <div>bye</div>} */}
              <div className="data1">
                <div className="btn1">
                  <div className="q-btn-mode1">
                    <button
                      className={activeButton === 1 ? "active1" : " "}
                      onClick={() => {
                        Upload(1);
                      }}
                    >
                      Upload Questions
                    </button>
                    <button
                      className={activeButton === 2 ? "active1" : " "}
                      onClick={() => {
                        Recent(2);
                      }}
                    >
                      Recent
                    </button>
                  </div>
                  <div className="quiz-sh-fl">
                    <div className="user-search df">
                      <input
                        // type="text"
                        placeholder="Search Users"
                        // value={filteredData?.user || ""}
                        // onChange={handleInputChange}
                        name="user"
                      />
                      <img src={searchIcon} alt="icon" />
                    </div>
                    <Popover
                      trigger="click"
                      open={true}
                      placement="bottomRight"
                      // onOpenChange={handleOpenChange}
                      // content={
                      //   <FilterUser
                      //     // hide={hide}
                      //     // roles={roles}
                      //     // names={names}
                      //     // userList={userList}
                      //     // filteredData={filteredData}
                      //     // setFilteredData={setFilteredData}
                      //   />
                      // }
                    >
                      <Space>
                        <span
                          className="filter-head"
                          data-tooltip-id="tooltip-arrow"
                          data-tooltip-content="Filter"
                        >
                          <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                          <img src={filterOn ? green_down : down} alt="icon" />
                        </span>
                      </Space>
                    </Popover>
                  </div>
                </div>
                <div className="up-box1">
                  {/* <QuizDrag Comp={clicked} /> */}

                  <div className="q-details">
                    <div className="q-title">
                      <img src={Document} alt="" />
                      <h1>
                        Welding Quiz 1 <p>2m ago</p>
                      </h1>
                    </div>
                    <div className="q-para">
                      <h1>
                        Scheduled On <span>10 Nov, Thu; 11.30AM</span>
                      </h1>

                      <h1>
                        Duration
                        <span>30 mins</span>
                      </h1>

                      <h1>
                        No of Questions
                        <span>30 mins</span>
                      </h1>
                      <img src={Delete} onClick={() => alert("image")} />
                    </div>
                  </div>
                  <div className="q-details">
                    <div className="q-title">
                      <img src={Pdf} alt="" />
                      <h1>
                        Welding Quiz 1 <p>2m ago</p>
                      </h1>
                    </div>
                    <div className="q-para">
                      <h1>
                        Scheduled On <span>10 Nov, Thu; 11.30AM</span>
                      </h1>

                      <h1>
                        Duration
                        <span>30 mins</span>
                      </h1>
                      <h1>
                        No of Questions
                        <span>30 mins</span>
                      </h1>
                      <img src={Delete} onClick={() => alert("image")} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>

        {!clicked && !isUpload && (
          <div className="q-line">
            <div className="line">
              <img src={Line} />
            </div>

            <h1 className="q-txt">Or</h1>
          </div>
        )}
        {/* right side  */}

        {!clicked && !isUpload && (
          <div className="q-down">
            <div className="qf-form" onClick={showModal}>
              <h1>Enter Questions Manually</h1>
              <form className="qf-data">
                <p>Enter Questions Here</p>
                <div className="q-radio">
                  <label>
                    <input
                      type="radio"
                      value="option1"
                      name="quizoption"
                      color="red"
                    />
                    A. Enter the option here
                  </label>
                </div>
                <div className="q-radio">
                  <label>
                    <input type="radio" name="quizoption" value="option2" />
                    B. Enter the option here
                  </label>
                </div>
                <div className="q-radio">
                  <label>
                    <input type="radio" name="quizoption" value="option3" />
                    C. Enter the option here
                  </label>
                </div>
                <div className="q-radio">
                  <label>
                    <input type="radio" name="quizoption" value="option3" />
                    D. Enter the option here
                  </label>
                </div>
              </form>
            </div>
            <Modal
              centered
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              className="edit-popup"
              width={1300}
              height={700}
            >
              <div className="model-down">
                <div className="model-title">
                  <h1
                    contenteditable="true"
                    suppressContentEditableWarning={true}
                    onInput={handleInput}
                  >
                    Enter the Question Title Here
                  </h1>
                  {console.log(content)}
                  <div className="model-duration">
                    <h1>
                      Duration
                      <input
                        type="text"
                        style={{ border: "2px solid black" }}
                        placeholder="XX"
                        max={2}
                      />
                      Mins
                    </h1>
                    <h1>
                      No. of questions
                      <input
                        type="text"
                        style={{ border: "2px solid black" }}
                        placeholder="X/X"
                      />
                    </h1>
                  </div>
                </div>

                <div className="model-form">
                  <form className="model-data">
                    <p contenteditable="true">Enter Questions Here</p>
                    <div className="model-radio">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          name="quizoption"
                          color="red"
                        />
                        A. Enter the Option Here
                      </label>
                    </div>
                    <div className="model-radio">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          name="quizoption"
                          color="red"
                        />
                        B. Enter the Option Here
                      </label>
                    </div>
                    <div className="model-radio">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          name="quizoption"
                          color="red"
                        />
                        C. Enter the Option Here
                      </label>
                    </div>
                    <div className="model-radio">
                      <label>
                        <input
                          type="radio"
                          value="option1"
                          name="quizoption"
                          color="red"
                        />
                        D. Enter the Option Here
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        )}
        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </section>
    </>
  );
};

export default Quiz;
