import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const AwaitingOrderTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  totalCount,
  defaultPageSize,
  DateSorter,
  searchData,
}) => {
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.substring(4),
          "",
          record.reference_number_class
        ),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(146),
      render: () => <span className="awo status">Awaiting order</span>,
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(114),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Awaiting Order",
      dataIndex: "quotation_time",
      key: "quotation_time",
      sorter: (a, b) =>
        (a.quotation_time || "").localeCompare(b.quotation_time || ""),
      width: rem(114),
      render: (text) => {
        const quoteDate = new Date(text);
        const quoteAgeingDays = !isNaN(quoteDate)
          ? Math.floor((new Date() - quoteDate) / (1000 * 60 * 60 * 24))
          : null;

        return (
          <span>
            {text
              ? quoteAgeingDays !== null
                ? `${quoteAgeingDays} days`
                : "DataNR"
              : "DataNR"}
          </span>
        );
      },
    },
    {
      title: "Buyer Seller POC",
      dataIndex: "client_person_name",
      key: "client_person_name",
      width: rem(134),
      className: "noPadding tl",
      style: { padding: 0 },
      render: (text, record) => (
        <span className="df flexColumn align-start">
          {renderEmptyOrValue(text, false, record.client_person_name_class)}

          {renderEmptyOrValue(
            record.client_email ? record.client_email.split("<")[0] : "", // Extract email up to '<' character
            false,
            record.client_email_class
          )}
        </span>
      ),
      sorter: (a, b) =>
        (a.client_person_name || "").localeCompare(b.client_person_name || ""),
    },
    {
      title: "Enquiry Subject",
      dataIndex: "client_subject",
      key: "client_subject",
      width: rem(174),
      className: "tl",
      render: (text, record) =>
        renderEmptyOrValue(text, "", record.client_subject_class),
      sorter: (a, b) =>
        (a.client_subject || "").localeCompare(b.client_subject || ""),
    },
    {
      title: `Buyer / Seller Time`,
      dataIndex: "client_email_time",
      key: "client_email_time",
      className: "noPadding",

      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
      sorter: DateSorter("client_email_time"),
    },
    {
      title: `Quotation Email Time`,
      dataIndex: "quotation_time",
      key: "quotation_time",
      className: "noPadding",

      sorter: DateSorter("quotation_time"),
      render: (text, record) => (
        <span className="df justify-center">
          <span
            className="df flexColumn mAuto"
            style={{ alignItems: "baseline" }}
          >
            {renderEmptyOrValue(text, "", "", "date")}

            {renderEmptyOrValue(text, "", "", "time")}
          </span>
        </span>
      ),
      width: rem(134),
    },
    // {
    //   title: " # of Follow-ups",
    //   dataIndex: "reminder_statu",
    //   key: "reminder_statu",
    //   width: rem(190),
    //   render: (text) => renderEmptyOrValue(text),
    // },
  ];

  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const matchesFilteredData =
          (user?.reference_number || "")
            .toLowerCase()
            .includes((filteredData?.reference_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData.client_email_from
            ? new Date(user.client_email_time?.split(" ")[0]) >=
              new Date(filteredData.client_email_from)
            : true) &&
          (filteredData.client_email_to
            ? new Date(user.client_email_time?.split(" ")[0]) <=
              new Date(filteredData.client_email_to)
            : true) &&
          (filteredData.sales_email_from
            ? new Date(user.sales_email_time?.split(" ")[0]) >=
              new Date(filteredData.sales_email_from)
            : true) &&
          (filteredData.sales_email_to
            ? new Date(user.sales_email_time?.split(" ")[0]) <=
              new Date(filteredData.sales_email_to)
            : true) &&
          (filteredData.ack_email_from
            ? new Date(user.ack_time?.split(" ")[0]) >=
              new Date(filteredData.ack_email_from)
            : true) &&
          (filteredData.ack_email_to
            ? new Date(user?.ack_time?.split(" ")[0]) <=
              new Date(filteredData?.ack_email_to)
            : true) &&
          (filteredData.quotation_email_from
            ? new Date(user?.quotation_time?.split(" ")[0]) >=
              new Date(filteredData?.quotation_email_from)
            : true) &&
          (filteredData.quotation_email_to
            ? new Date(user?.quotation_time?.split(" ")[0]) <=
              new Date(filteredData?.quotation_email_to)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true);

        const matchesSearchData = searchData
          ? (user?.reference_number || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        return matchesFilteredData && matchesSearchData;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user.reference_number
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user.client_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user.sales_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientSubject =
          searchData &&
          user.client_subject?.toLowerCase().includes(searchData.toLowerCase());

        const matchClientEmail =
          searchData &&
          user.client_email?.toLowerCase().includes(searchData.toLowerCase());

        return {
          ...user, // Return all original user data
          reference_number_class: matchReferenceNumber
            ? "highlighted-text"
            : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
          client_email_class: matchClientEmail ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData]);

  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default AwaitingOrderTable;
