import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { rem } from "../../../Components/Rem_func";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import _http from "../../../Utils/Api/_http";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const RevenueChart = ({ formatNumber }) => {
  const [revenue, setRevenue] = useState("");
  const fetchData = async () => {
    try {
      const response = await _http.get("/api/top-ten-sales");
      setRevenue(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const data = {
    labels: revenue?.top_10_clients?.map((client) => client?.client_name),
    datasets: [
      {
        type: "bar",
        label: "Awarded Value",
        data: revenue?.top_10_clients?.map(
          (client) => client?.total_awarded_value
        ),
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
          gradient.addColorStop(1, "#E3FFF0");

          gradient.addColorStop(0.21, "#1E6641");
          return gradient;
        },

        // borderColor: "rgba(0, 128, 0, 1)",
        // borderWidth: 1,
        // hoverBackgroundColor: "rgba(31, 103, 66, 0.31)",
        barPercentage: 0.5,
        borderRadius: 10,
        order: 2,
        yAxisID: "y",
      },
      {
        type: "line",
        // label: "Awarded Value",
        data: revenue?.top_10_clients?.map(
          (client) => client?.conversion_percentage
        ),
        borderWidth: 2,
        borderColor: "#878787",
        PointBorderColor: "#E0E7ED",
        pointBackgroundColor: "#FFF",
        pointBorderColor: "#FFF",
        pointRadius: 3,
        fill: false,
        order: 1,
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          color: "#A3AED0",
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          beginAtZero: true,
          callback: function (value) {
            return `${formatNumber(value)}`; // Add '%' after the value for percentage
          },
        },
        border: {
          display: false, // Hide the y-axis line
        },
        title: {
          display: false,
        },
      },
      y1: {
        position: "right",
        beginAtZero: true,
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: "#A3AED0",
          callback: function (value) {
            return `${value}%`; // Add '%' after the value for percentage
          },
        },
        title: {
          display: false,
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.datasetIndex === 1) {
              return `Conversion Rate: ${tooltipItem.raw.toFixed(2)}%`;
            }
            return `Awarded Value: ${formatNumber(tooltipItem.raw)}`;
          },
        },
      },
    },
  };

  return (
    <div className="revenue-graph df flexColumn space-between">
      <div className="revenue-title df justify-center">
        Top revenue generating clients
      </div>
      <div className="df revenue-label">
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#1E6641"
            />
          </svg>
          Awarded value
        </div>
        <div className="df" style={{ gap: rem(6) }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(12)}
            height={rem(12)}
            viewBox="0 0 12 12"
            fill="none"
          >
            <ellipse
              cx="5.96678"
              cy="5.60136"
              rx="5.96678"
              ry="5.60136"
              fill="#BEC8D0"
            />
          </svg>
          successful conversion divided by opportunity
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: rem(225),
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default RevenueChart;
