import React, { useState, useEffect, useCallback } from "react";
import SalesTable from "./SalesTable";
import SalesRange from "./SalesRange";
import RevenueChart from "./RevenueGraph";
import "./Dashboard.scss";
import { rem } from "../../../Components/Rem_func";
import DeclineReason from "./DeclineReason";
import QuotedResponse from "./QuotedResponse";
import BranchBarChart from "./BranchBarChart";
import SalesBieChart from "./SalesBieChart";
import SalesHeader from "./SalesHeader";
import _http from "../../../Utils/Api/_http";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
import CrossIcon from "../../../Components/Svg/CrossIcon";
const DashBoard = () => {
  const [enquiries, setEnquiries] = useState({});
  const [userList, setUserList] = useState([]);
  const [filteredData, setFilteredData] = useState({
    managers: [],
    teamleads: [],
    employees: [],
  });
  const [totalCurrencyValue, setTotalCurrencyValue] = useState(0);
  const [totalAckMailCount, setTotalAckMailCount] = useState(0);
  const [totalAwardedValue, setTotalAwardedValue] = useState(0);
  const [conversionPercent, setConversionPercent] = useState(0);
  const [conversionPercentAwarded, setConversionPercentAwarded] = useState(0);

  const access = useSelector(selectUserData);
  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: [],
    quarter: [],
    start_date: "",
    end_date: "",
    currency: "",
  });
  const [filterOn, setFilterOn] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const params = {
        ...filters,
        year: filters.year.length ? filters.year.join(",") : "", // Convert year array to comma-separated string
        quarter: filters.quarter.length ? filters.quarter.join(",") : "", // Convert quarter array to comma-separated string
      }; // Conditionally add location param

      const [salesResponse] = await Promise.all([
        _http.get("/api/filtered-dashboard-api", { params }),
      ]);

      // Set enquiries data
      setEnquiries((prev) => ({
        ...prev,
        ...salesResponse.data,
      }));

      // Set user list
      setUserList(salesResponse.data.data || []);

      // Set total acknowledgment count and total currency value
      setTotalAckMailCount(salesResponse?.data?.total_ackmail_count || 0);
      setTotalCurrencyValue(salesResponse?.data?.total_quoted_order_value || 0);
      setTotalAwardedValue(salesResponse.data?.total_awarded_aed_value || 0);
      setConversionPercent(salesResponse.data.conversion_percent || 0);
      setConversionPercentAwarded(
        salesResponse.data.conversionPercentAwarded || 0
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatNumber = (num) => {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Convert to billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Convert to millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Convert to thousands
    } else {
      return num?.toString(); // If less than a thousand, return as is
    }
  };

  // Calculate totals based on filterData
  const calculateTotals = useCallback(() => {
    let totalValue = 0;
    let totalAckCount = 0;
    let totalAwarded = 0;
    let conversionpercent = 0;
    let conversionpercentAwarded = 0;
    // Get selected filters based on role
    // if (
    //   !filteredData.employees ||
    //   !filteredData.managers ||
    //   !filteredData.teamleads
    // ) {
    //   return;
    // }
    if (access.role_name === "Teamlead") {
      let totalValue = 0; // Initialize total value
      let totalAckCount = 0; // Initialize total acknowledgment count

      // Check if any specific employees are selected in the filter
      if (filteredData?.employees?.length > 0) {
        userList[0]?.employees?.forEach((employee) => {
          if (filteredData?.employees.includes(employee.employee)) {
            totalValue += employee?.total_currency_value || 0; // Accumulate total currency value
            totalAckCount += employee?.ackmail_count || 0; // Accumulate acknowledgment count
            totalAwarded += employee?.awarded_aed_value || 0;
            conversionpercent += employee?.conversion_percent || 0;
            conversionpercentAwarded += employee?.conversion_percent || 0;
          }
        });
      } else {
        // If no filters are applied, calculate totals for all employees

        userList[0]?.employees.forEach((item) => {
          totalValue += item?.total_currency_value || 0;
          totalAckCount += item?.ackmail_count || 0;
          totalAwarded += item?.awarded_aed_value || 0;
          conversionpercent += item?.conversion_percent || 0;
          conversionpercentAwarded += item?.conversion_percent || 0;
        });
      }

      // Set the calculated totals
      setTotalCurrencyValue(totalValue); // Update total currency value
      setTotalAckMailCount(totalAckCount); // Update total acknowledgment count
      setTotalAwardedValue(totalAwarded);
      setConversionPercent(conversionpercent);
      setConversionPercentAwarded(conversionpercentAwarded);
    }

    if (access.role_name === "Manager") {
      // If there are selected team leads in the filter
      if (filteredData.teamleads.length > 0) {
        // If there are selected employees within the filtered team leads
        if (filteredData.employees.length > 0) {
          userList?.forEach((teamLead) => {
            if (filteredData.teamleads.includes(teamLead.team_lead)) {
              // Only process selected employees under the selected team leads
              teamLead?.employees?.forEach((emp) => {
                if (filteredData.employees.includes(emp.employee)) {
                  totalValue += emp?.total_currency_value || 0;
                  totalAckCount += emp?.ackmail_count || 0;
                  totalAwarded += emp?.awarded_aed_value || 0;
                  conversionpercent += emp?.conversion_percent || 0;
                  conversionpercentAwarded += emp?.conversion_percent || 0;
                }
              });
            }
          });
        } else {
          // No specific employees are filtered, sum all employees under the selected team leads
          userList?.forEach((teamLead) => {
            if (filteredData.teamleads.includes(teamLead.team_lead)) {
              teamLead?.employees?.forEach((emp) => {
                totalValue += emp?.total_currency_value || 0;
                totalAckCount += emp?.ackmail_count || 0;
                totalAwarded += emp?.awarded_aed_value || 0;
                conversionpercent += emp?.conversion_percent || 0;
                conversionpercentAwarded += emp?.conversion_percent || 0;
              });
            }
          });
        }
      } else {
        // No specific team leads are filtered
        if (filteredData.employees.length > 0) {
          // If only employees are filtered without team leads
          userList?.forEach((teamLead) => {
            teamLead?.employees?.forEach((emp) => {
              if (filteredData.employees.includes(emp.employee)) {
                totalValue += emp?.total_currency_value || 0;
                totalAckCount += emp?.ackmail_count || 0;
                totalAwarded += emp?.awarded_aed_value || 0;
                conversionpercent += emp?.conversion_percent || 0;
                conversionpercentAwarded += emp?.conversion_percent || 0;
              }
            });
          });
        } else {
          // No filters, sum all employees under all team leads

          userList?.forEach((teamLead) => {
            teamLead?.team_leads?.forEach((emps) =>
              emps.employees?.forEach((emp) => {
                totalValue += emp?.total_currency_value || 0;
                totalAckCount += emp?.ackmail_count || 0;
                totalAwarded += emp?.awarded_aed_value || 0;
                conversionpercent += emp?.conversion_percent || 0;
                conversionpercentAwarded += emp?.conversion_percent || 0;
              })
            );
          });
        }
      }

      // Update the total currency value and acknowledgment mail count
      setTotalCurrencyValue(totalValue);
      setTotalAckMailCount(totalAckCount);
      setTotalAwardedValue(totalAwarded);
      setConversionPercent(conversionpercent);
      setConversionPercentAwarded(conversionpercentAwarded);

      return;
    }
    if (access.role_name === "admin") {
      if (filteredData.managers.length > 0) {
        // If specific managers are selected
        userList?.forEach((manager) => {
          if (filteredData.managers.includes(manager.manager)) {
            // Check if specific team leads are selected
            if (filteredData.teamleads.length > 0) {
              manager?.team_leads?.forEach((teamLead) => {
                if (filteredData.teamleads.includes(teamLead.team_lead)) {
                  // If team leads are selected, check for filtered employees
                  if (filteredData.employees.length > 0) {
                    teamLead?.employees?.forEach((emp) => {
                      if (filteredData.employees.includes(emp.employee)) {
                        totalValue += emp?.total_currency_value || 0;
                        totalAckCount += emp?.ackmail_count || 0;
                        totalAwarded += emp?.awarded_aed_value || 0;
                        conversionpercent += emp?.conversion_percent || 0;
                        conversionpercentAwarded +=
                          emp?.conversion_percent || 0;
                      }
                    });
                  } else {
                    // No specific employees are filtered, sum all employees under the team lead
                    teamLead?.employees?.forEach((emp) => {
                      totalValue += emp?.total_currency_value || 0;
                      totalAckCount += emp?.ackmail_count || 0;
                      totalAwarded += emp?.awarded_aed_value || 0;
                      conversionpercent += emp?.conversion_percent || 0;
                      conversionpercentAwarded += emp?.conversion_percent || 0;
                    });
                  }
                }
              });
            } else {
              // No team leads selected, check for filtered employees under the manager
              if (filteredData.employees.length > 0) {
                manager?.team_leads?.forEach((teamLead) => {
                  teamLead?.employees?.forEach((emp) => {
                    if (filteredData.employees.includes(emp.employee)) {
                      totalValue += emp?.total_currency_value || 0;
                      totalAckCount += emp?.ackmail_count || 0;
                      totalAwarded += emp?.awarded_aed_value || 0;
                      conversionpercent += emp?.conversion_percent || 0;
                      conversionpercentAwarded += emp?.conversion_percent || 0;
                    }
                  });
                });
              } else {
                // No specific employees are filtered, sum all employees under the manager
                manager?.team_leads?.forEach((teamLead) => {
                  teamLead?.employees?.forEach((emp) => {
                    totalValue += emp?.total_currency_value || 0;
                    totalAckCount += emp?.ackmail_count || 0;
                    totalAwarded += emp?.awarded_aed_value || 0;
                    conversionpercent += emp?.conversion_percent || 0;
                    conversionpercentAwarded += emp?.conversion_percent || 0;
                  });
                });
              }
            }
          }
        });
      } else {
        // No specific managers are selected
        if (filteredData.teamleads.length > 0) {
          // If specific team leads are selected, check for filtered employees
          userList?.forEach((manager) => {
            manager?.team_leads?.forEach((teamLead) => {
              if (filteredData.teamleads.includes(teamLead.team_lead)) {
                if (filteredData.employees.length > 0) {
                  // If specific employees are selected, sum only those
                  teamLead?.employees?.forEach((emp) => {
                    if (filteredData.employees.includes(emp.employee)) {
                      totalValue += emp?.total_currency_value || 0;
                      totalAckCount += emp?.ackmail_count || 0;
                      totalAwarded += emp?.awarded_aed_value || 0;
                      conversionpercent += emp?.conversion_percent || 0;
                      conversionpercentAwarded += emp?.conversion_percent || 0;
                    }
                  });
                } else {
                  // No specific employees are filtered, sum all under the team lead
                  teamLead?.employees?.forEach((emp) => {
                    totalValue += emp?.total_currency_value || 0;
                    totalAckCount += emp?.ackmail_count || 0;
                    totalAwarded += emp?.awarded_aed_value || 0;
                    conversionpercent += emp?.conversion_percent || 0;
                    conversionpercentAwarded += emp?.conversion_percent || 0;
                  });
                }
              }
            });
          });
        } else {
          // No team leads or employees are selected, sum everything
          if (filteredData.employees.length > 0) {
            // If only employees are selected, sum just those employees
            userList?.forEach((manager) => {
              manager?.team_leads?.forEach((teamLead) => {
                teamLead?.employees?.forEach((emp) => {
                  if (filteredData.employees.includes(emp.employee)) {
                    totalValue += emp?.total_currency_value || 0;
                    totalAckCount += emp?.ackmail_count || 0;
                    totalAwarded += emp?.awarded_aed_value || 0;
                    conversionpercent += emp?.conversion_percent || 0;
                    conversionpercentAwarded += emp?.conversion_percent || 0;
                  }
                });
              });
            });
          } else {
            // No filters applied, sum everything
            userList?.forEach((manager) => {
              manager?.team_leads?.forEach((teamLead) => {
                teamLead?.employees?.forEach((emp) => {
                  totalValue += emp?.total_currency_value || 0;
                  totalAckCount += emp?.ackmail_count || 0;
                  totalAwarded += emp?.awarded_aed_value || 0;
                  conversionpercent += emp?.conversion_percent || 0;
                  conversionpercentAwarded += emp?.conversion_percent || 0;
                });
              });
            });
          }
        }
      }

      // Update total values
      setTotalCurrencyValue(totalValue);
      setTotalAckMailCount(totalAckCount);
      setTotalAwardedValue(totalAwarded);
      setConversionPercent(conversionpercent);
      setConversionPercentAwarded(conversionpercentAwarded);
    }
  }, [access.role_name, filteredData, userList]);

  useEffect(() => {
    calculateTotals();
  }, [filteredData, userList, calculateTotals]);
  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object?.values(filters)?.some((value) => value.length > 0);
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);
  const removeThisFilter = (key, index = null) => {
    setFilteredData((prev) => {
      const updatedData = { ...prev };

      if (Array.isArray(prev[key])) {
        if (index !== null) {
          // Handle array case
          const updatedArray = prev[key].filter((_, i) => i !== index); // Remove specific item by index
          updatedData[key] = updatedArray;

          if (key === "managers") {
            // If a manager is removed, also remove associated team leads and employees
            const selectedManagers = userList.filter((manager) =>
              updatedArray.includes(manager.manager)
            );

            const filteredTeamLeads = selectedManagers.flatMap((item) =>
              item.team_leads.map((tl) => tl.team_lead)
            );
            updatedData.teamleads = updatedData.teamleads.filter((tl) =>
              filteredTeamLeads.includes(tl)
            );

            const filteredEmployees = selectedManagers.flatMap((item) =>
              item.team_leads.flatMap((tl) =>
                tl.employees.map((emp) => emp.employee)
              )
            );
            updatedData.employees = updatedData.employees.filter((emp) =>
              filteredEmployees.includes(emp)
            );
          }

          if (key === "teamleads") {
            // If a team lead is removed, also remove associated employees
            const selectedTeamLeads = userList
              .flatMap((manager) => manager.team_leads)
              .filter((tl) => updatedArray.includes(tl.team_lead));

            const filteredEmployees = selectedTeamLeads.flatMap((tl) =>
              tl.employees.map((emp) => emp.employee)
            );
            updatedData.employees = updatedData.employees.filter((emp) =>
              filteredEmployees.includes(emp)
            );
          }
        } else {
          // If no index is provided, clear the entire array
          updatedData[key] = [];

          // If clearing managers, clear team leads and employees
          if (key === "managers") {
            updatedData.teamleads = [];
            updatedData.employees = [];
          }

          // If clearing team leads, clear employees
          if (key === "teamleads") {
            updatedData.employees = [];
          }
        }
      } else {
        // Handle single values
        updatedData[key] = "";
      }

      return updatedData;
    });
  };

  return (
    <section className="df sales-dashboard flexColumn">
      <div className="w100">
        <SalesHeader
          userList={userList}
          filteredData={filteredData}
          setFilters={setFilters}
          filters={filters}
          filterOn={filterOn}
          setFilteredData={setFilteredData}
          DashBoard={true}
        />
      </div>
      <div className="df filter-section" style={{ width: rem(1100) }}>
        {filterOn && filteredData && (
          <div
            className="filtered-data-item df"
            style={{ paddingBottom: rem(6) }}
          >
            {Object.entries(filteredData).map(
              ([key, value]) =>
                Array.isArray(value) &&
                value.length > 0 && (
                  <div className="df filtered-array" key={key}>
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (l) => l.toUpperCase())}
                    :
                    {value.map((item, index) => (
                      <span
                        key={`${key}-${index}`}
                        className="df filtered-option"
                      >
                        {" "}
                        <span
                          className="filtered-value"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.toLowerCase() || "N/A"}
                        </span>
                        <span
                          onClick={() => removeThisFilter(key, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <CrossIcon />
                        </span>
                      </span>
                    ))}
                  </div>
                )
            )}
          </div>
        )}
      </div>
      <div
        className="df align-start sales-contents"
        style={{ paddingLeft: rem(11) }}
      >
        <div className="df flexColumn graph-content">
          <SalesRange
            enquires={enquiries}
            filteredData={filteredData}
            totalCurrencyValue={totalCurrencyValue}
            totalAckMailCount={totalAckMailCount}
            totalAwardedValue={totalAwardedValue}
            conversionPercent={conversionPercent}
            conversionPercentAwarded={conversionPercentAwarded}
            formatNumber={formatNumber}
          />

          <div className="flexBox sales-graph" style={{ width: rem(635) }}>
            <BranchBarChart formatNumber={formatNumber} />
            <SalesBieChart />
            <DeclineReason />
            <QuotedResponse />
          </div>
        </div>
        <div className="df flexColumn sales-graph-right">
          <SalesTable
            userList={userList}
            filteredData={filteredData}
            formatNumber={formatNumber}
          />
          <RevenueChart formatNumber={formatNumber} />
        </div>
      </div>
    </section>
  );
};

export default DashBoard;
