import React from "react";

const SalesRange = ({
  enquires,
  filterData,
  totalCurrencyValue,
  totalAckMailCount,
  totalAwardedValue,
  conversionPercent,
  conversionPercentAwarded,
  formatNumber,
}) => {
  // Calculate values from filterData or fallback to props
  const totalAckCount = filterData?.totalAckCount || totalAckMailCount || 0;
  const totalValue =
    filterData?.totalValue?.toFixed(2) || totalCurrencyValue?.toFixed(2) || 0;
  const awardedValue = filterData?.totalAwarded || totalAwardedValue || 0;
  const conversationPercentAgainstEnquires =
    conversionPercent || filterData?.conversation_percent_against_enquires || 0;
  const conversationPercentAgainstQuoted =
    conversionPercentAwarded ||
    filterData?.conversation_percent_against_quoted ||
    0;

  // Calculate strokeDasharray values for SVG based on percentage
  const calcStrokeDasharray = (percent) => {
    const fixedPercent = Math.max(0, Math.min(percent, 100)); // Ensure percent is between 0 and 100
    const dashValue = (fixedPercent / 100) * 50; // 50 is half of the circle
    return `${dashValue} ${51 - dashValue}`;
  };

  return (
    <div className="sales-range df">
      <div className="range-box flexBox">
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{totalAckCount}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (AED)</span>
          <span className="value">{formatNumber(totalValue)}</span>
        </div>
        <div className="box df">
          <span className="label">Awarded (AED)</span>
          <span className="value">{formatNumber(awardedValue)}</span>
        </div>
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (USD)</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label">Awarded (USD)</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label"># of Enquiries</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label">Quoted (OMR)</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label">Awarded (OMR)</span>
          <span className="value">{0}</span>
        </div>
        <div className="box df">
          <span className="label">Conversation % against Enquiries</span>
          <div className="range-bar">
            <div className="semi-circular-progress">
              <svg viewBox="0 0 36 18" className="semi-circular-chart">
                <path
                  className="semi-circle-bg"
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#E0E7ED"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  className="semi-circle"
                  strokeDasharray={calcStrokeDasharray(
                    conversationPercentAgainstEnquires
                  )}
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#1E6641"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <text
                  x="18"
                  y="12"
                  textAnchor="middle" // Align center horizontally
                  fontSize="6"
                  dominantBaseline={"mathematical"}
                >
                  {`${conversationPercentAgainstEnquires.toFixed(2)}%`}
                </text>
              </svg>
            </div>
          </div>
        </div>
        <div className="box df">
          <span className="label">Conversation % against Quoted</span>
          <div className="range-bar">
            <div className="semi-circular-progress">
              <svg viewBox="0 0 36 18" className="semi-circular-chart">
                <path
                  className="semi-circle-bg"
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#E0E7ED"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  className="semi-circle"
                  strokeDasharray={calcStrokeDasharray(
                    conversationPercentAgainstQuoted
                  )}
                  d="M18 18 m -16 0 a 16 16 0 0 1 32 0"
                  stroke="#1E6641"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <text
                  x="18"
                  y="12"
                  textAnchor="middle" // Align center horizontally
                  fontSize="6"
                  dominantBaseline={"mathematical"}
                >
                  {`${conversationPercentAgainstQuoted.toFixed(2)}%`}
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesRange;
