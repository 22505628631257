import React, { useState, useEffect } from "react";
import "./UserFile.scss";
import profilePic from "../../Assets/SideBarIcon/Background.svg";
import { rem } from "../../Components/Rem_func";
import { selectUserData, setUserdata } from "../../Redux/Reducers";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import SnackBar from "../../Components/SnackBar";
const UserProfile = () => {
  const [access, setAccess] = useState();
  const navigate = useNavigate();
  const [openmodel, setOpen] = React.useState(false);
  const [errors, setError] = useState("");
  const [toast, setToast] = useState({ Submit: false, error: false });
  const isAccess = useSelector(selectUserData);
  const dispatch = useDispatch();

  // Effect to set access state from sessionStorage
  useEffect(() => {
    setAccess(isAccess);
  }, [isAccess]);

  const handletoast = () => {
    setToast({ Submit: false, error: false });
  };

  const handleSaveToast = (submit, error, message) => {
    setToast({ Submit: submit, error: error });
    setError(message);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("SpirajwtToken");
    sessionStorage.removeItem("dropdownState");
    dispatch(setUserdata(""));
    navigate("/Login");
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <section className="user-profile flexBox flexColumn">
      <div className="profile-pic">
        <img src={profilePic} alt="profile" />
        <div className="df edit-profile justify-center">
          <span>Edit profile picture</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={rem(18)}
            height={rem(18)}
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M0 14.4624V17.5024C0 17.7824 0.22 18.0024 0.5 18.0024H3.54C3.67 18.0024 3.8 17.9524 3.89 17.8524L14.81 6.94244L11.06 3.19244L0.15 14.1024C0.0500001 14.2024 0 14.3224 0 14.4624ZM17.71 4.04244C17.8027 3.94993 17.8762 3.84004 17.9264 3.71907C17.9766 3.59809 18.0024 3.46841 18.0024 3.33744C18.0024 3.20648 17.9766 3.07679 17.9264 2.95582C17.8762 2.83485 17.8027 2.72496 17.71 2.63244L15.37 0.292444C15.2775 0.19974 15.1676 0.126193 15.0466 0.0760114C14.9257 0.02583 14.796 0 14.665 0C14.534 0 14.4043 0.02583 14.2834 0.0760114C14.1624 0.126193 14.0525 0.19974 13.96 0.292444L12.13 2.12244L15.88 5.87244L17.71 4.04244Z"
              fill="#858585"
            />
          </svg>
        </div>
      </div>
      <div
        className="flexBox flexColumn space-between"
        style={{ gap: rem(120), flex: "1" }}
      >
        <div className="user-detail df  align-start">
          <div className="df flexColumn align-start" style={{ gap: rem(15) }}>
            <span className="label">User name</span>
            <span className="label">Mail Id</span>
          </div>
          <div className="df flexColumn" style={{ gap: rem(15) }}>
            <span className="value textCaps">{access?.name.toLowerCase()}</span>

            <span className="value">{access?.user_email}</span>
          </div>
        </div>
        <div
          className="df flexColumn align-start"
          style={{ gap: rem(27), paddingLeft: rem(48) }}
        >
          <button className="changePassword" onClick={handleOpen}>
            Change Password
          </button>
          <button className="logout df" onClick={handleLogout}>
            LogOut{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(18)}
              height={rem(18)}
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z"
                fill="#454545"
              />
            </svg>
          </button>
        </div>
      </div>
      {openmodel && (
        <ChangePassword
          openmodel={openmodel}
          handleClose={handleClose}
          handleSaveToast={handleSaveToast}
        />
      )}
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.Submit}
      />
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
    </section>
  );
};

export default UserProfile;
