import React, { useEffect, useMemo } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const UserDataTable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  defaultPageSize,
  totalCount,
  searchData,
}) => {
  // Memoized filteredUsers to optimize performance
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        // Filter based on filteredData
        const matchesFilteredData =
          (user?.reference_number || "")
            .toLowerCase()
            .includes((filteredData?.reference_number || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData?.client_email_from
            ? new Date(user?.client_email_time?.split(" ")[0]) >=
              new Date(filteredData?.client_email_from)
            : true) &&
          (filteredData?.client_email_to
            ? new Date(user?.client_email_time?.split(" ")[0]) <=
              new Date(filteredData?.client_email_to)
            : true) &&
          (filteredData?.sales_email_from
            ? new Date(user?.sales_email_time?.split(" ")[0]) >=
              new Date(filteredData?.sales_email_from)
            : true) &&
          (filteredData?.sales_email_to
            ? new Date(user?.sales_email_time?.split(" ")[0]) <=
              new Date(filteredData?.sales_email_to)
            : true) &&
          (filteredData?.ack_email_from
            ? new Date(user?.ack_time?.split(" ")[0]) >=
              new Date(filteredData?.ack_email_from)
            : true) &&
          (filteredData?.ack_email_to
            ? new Date(user?.ack_time?.split(" ")[0]) <=
              new Date(filteredData?.ack_email_to)
            : true) &&
          (filteredData?.quotation_email_from
            ? new Date(user?.quotation_time?.split(" ")[0]) >=
              new Date(filteredData?.quotation_email_from)
            : true) &&
          (filteredData?.quotation_email_to
            ? new Date(user?.quotation_time?.split(" ")[0]) <=
              new Date(filteredData?.quotation_email_to)
            : true) &&
          (filteredData?.order_date_from
            ? new Date(user?.order_date_time?.split(" ")[0]) >=
              new Date(filteredData?.order_date_from)
            : true) &&
          (filteredData?.order_date_to
            ? new Date(user?.order_date_time?.split(" ")[0]) <=
              new Date(filteredData?.order_date_to)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.max_order_value
            ? user?.order_value
              ? Number(filteredData?.max_order_value) >=
                Number(user?.order_value?.replace(/,/g, "") || 0)
              : false
            : true) &&
          (filteredData?.min_order_value
            ? user?.order_value
              ? Number(filteredData?.min_order_value) <=
                Number(user?.order_value?.replace(/,/g, ""))
              : false
            : true);
        // (filteredData.)

        // Search logic: check if any field contains the searchData string
        const matchesSearchData = searchData
          ? (user?.reference_number || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        return matchesFilteredData && matchesSearchData;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user?.reference_number
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchClientPersonName =
          searchData &&
          user?.client_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user?.sales_person_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        const matchClientSubject =
          searchData &&
          user?.client_subject
            ?.toLowerCase()
            .includes(searchData?.toLowerCase());

        return {
          ...user, // Return all original user data
          reference_number_class: matchReferenceNumber
            ? "highlighted-text"
            : "",
          client_person_name_class: matchClientPersonName
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_subject_class: matchClientSubject ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),

      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.substring(4),
          "",
          record.reference_number_class
        ),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      width: rem(136),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
    },
    {
      title: "Status",
      dataIndex: "reminder_status",
      key: "reminder_status",
      // sorter: (a, b) =>
      //   (a?.reminder_status || "").localeCompare(b?.reminder_status || ""),
      width: rem(146),
      render: (text, record) => {
        if (text === "order_placed") {
          return <span className="op status">Order Placed</span>;
        } else if (text === "success") {
          return <span className="awo status">Awaiting Order</span>;
        } else if (text === "pending") {
          return <span className="aqo status">Awaiting Quote</span>;
        } else if (!text) {
          return "DataNR";
        } else {
          return "MismatchF";
        }
      },
      className: "",
    },
    {
      title: "Awaiting Quote",
      dataIndex: "client_email_time",
      key: "client_email_time",
      sorter: (a, b) => {
        const quoteDateA = new Date(a.client_email_time || "");
        const quoteAgeingDaysA = !isNaN(quoteDateA)
          ? Math.floor((new Date() - quoteDateA) / (1000 * 60 * 60 * 24))
          : null;

        const quoteDateB = new Date(b.client_email_time || "");
        const quoteAgeingDaysB = !isNaN(quoteDateB)
          ? Math.floor((new Date() - quoteDateB) / (1000 * 60 * 60 * 24))
          : null;

        return quoteAgeingDaysA - quoteAgeingDaysB;
      },
      width: rem(104),
      render: (text, record) => {
        const quoteDate = new Date(text);
        const quoteAgeingDays = !isNaN(quoteDate)
          ? Math.floor((new Date() - quoteDate) / (1000 * 60 * 60 * 24))
          : null;

        return (
          <span>
            {text ? (
              quoteAgeingDays ? (
                `${quoteAgeingDays || 0} days`
              ) : quoteAgeingDays === 0 ? (
                "0 days"
              ) : (
                <span className="empty">MismatchF</span>
              )
            ) : (
              <span className="empty">DataNR</span>
            )}
          </span>
        );
      },
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },

      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Awaiting Order",
      dataIndex: "sales_email_time",
      key: "sales_email_time",
      sorter: (a, b) => {
        const orderDateA = new Date(a.order_date_time || "");
        const quotationDateA = new Date(a.quotation_time || "");
        const orderAgeingDaysA =
          !isNaN(orderDateA) && !isNaN(quotationDateA)
            ? Math.floor((orderDateA - quotationDateA) / (1000 * 60 * 60 * 24))
            : null;
        const orderDateB = new Date(b.order_date_time || "");
        const quotationDateB = new Date(b.quotation_time || "");
        const orderAgeingDaysB =
          !isNaN(orderDateB) && !isNaN(quotationDateB)
            ? Math.floor((orderDateB - quotationDateB) / (1000 * 60 * 60 * 24))
            : null;
        return orderAgeingDaysA - orderAgeingDaysB;
      },
      width: rem(104),
      render: (text, record) => {
        const orderDate = record.order_date_time
          ? new Date(record.order_date_time.split(" ")[0])
          : null;
        const quotationDate = record.quotation_time
          ? new Date(record.quotation_time.split(" ")[0])
          : null;

        const orderAgeingDays =
          orderDate && quotationDate
            ? Math.floor((orderDate - quotationDate) / (1000 * 60 * 60 * 24))
            : null;

        return (
          <span>
            {text ? (
              orderAgeingDays ? (
                `${orderAgeingDays} days`
              ) : (
                <span className="empty">
                  {record.reminder_status === "order_placed"
                    ? "MismatchF"
                    : "DataNR"}
                </span>
              )
            ) : (
              <span className="empty">DataNR</span>
            )}
          </span>
        );
      },
    },

    // {
    //   title: "#of Follow-ups",
    //   dataIndex: "client_email",
    //   key: "client_email",
    //   sorter: (a, b) =>
    //     (a?.client_email || "").localeCompare(b?.client_email || ""),
    //   // width: rem(180),
    //   render: (text) => renderEmptyOrValue(text),
    // },

    {
      title: "Order Value",
      dataIndex: "order_value",
      key: "order_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.order_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.order_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },

      width: rem(104),
      render: (text) => renderEmptyOrValue(text?.replace(/,/g, "")),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(104),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Order Closure Days",
      dataIndex: "order_closure_days",
      key: "order_closure_days",
      sorter: (a, b) =>
        (a.order_closure_days || "").localeCompare(b.order_closure_days || ""),
      width: rem(134),
      render: (text, record) => {
        const orderDate = record.order_date_time
          ? new Date(record.order_date_time.split(" ")[0])
          : null;
        const clientEmail = record.client_email_time
          ? new Date(record.client_email_time.split(" ")[0])
          : null;

        const orderClosureDays =
          orderDate && clientEmail
            ? Math.floor((orderDate - clientEmail) / (1000 * 60 * 60 * 24))
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${orderClosureDays} days`
            ) : (
              <span className="empty">DataNR</span>
            )}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default UserDataTable;
