import React from "react";
import SalesTracker from "../STcomponents/SalesTracker/SalesTracker";
import AQTable from "./AQTable";
const AwaitingQuotes = () => {
  return (
    <>
      <SalesTracker
        title={"Awaiting Quotes"}
        UserDataTable={AQTable}
        api={"/api/waiting_quote"}
        AwaitingQuotes={true}
      />
    </>
  );
};

export default AwaitingQuotes;
