import React, { useState, useEffect } from "react";
import "./STeditPopup.scss";
import { Modal } from "antd";
import InputField from "../../../../Components/InputField/InputField";
import { rem } from "../../../../Components/Rem_func";
import TextField from "../../../../Components/TextField/TextField";
import _http from "../../../../Utils/Api/_http";

const EditPopup = ({ user, handleClose, open, updatingFun }) => {
  // State to manage user data in the form
  const [userData, setUserData] = useState({
    ID: user.id,
    sales_person_name: user.from_person_name,
    from_email: user.from_email,
    client_email: user.client_email,
    from_subject: user.from_subject,
    from_email_time: user.from_email_time,
    plain_text: "Fetching...",
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    if (open) {
      const firstFocusableElement = document.querySelector(".user-popup input");
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    const fetchFile = async (ids) => {
      try {
        const response = await _http.get(`/api/get-email-file-content/${ids}`);
        setUserData({
          ...userData,
          plain_text: response.data.plain_text_content,
        });
        console.log(response.data.attachment_content[0].file);

        // Get the base64 string and handle the file display
        // const base64Data = response.data.attachment_content;
        // displayPDF(base64Data); // Call the function to handle the PDF rendering
      } catch (error) {
        console.log(error);
      }
    };

    fetchFile(userData.ID);
  }, [userData]);

  // Function to display the PDF from Base64
  // const cleanBase64 = (base64String) => {
  //   // Remove line breaks, spaces, or other potential issues
  //   return base64String.replace(/[^A-Za-z0-9+/=]/g, "");
  // };

  // const displayPDF = (base64Data) => {
  //   // Clean the base64 string
  //   const base64String = cleanBase64(base64Data.split(",")[1]); // Get the base64 content

  //   try {
  //     const byteCharacters = atob(base64String); // Decode it
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);

  //     // Create Blob and display PDF
  //     const blob = new Blob([byteArray], { type: "application/pdf" });
  //     const blobUrl = URL.createObjectURL(blob);

  //     setModalContent(
  //       <embed
  //         width={"100%"}
  //         height={"100%"}
  //         src={blobUrl}
  //         type="application/pdf"
  //       />
  //     );
  //     setModalVisible(true);
  //   } catch (error) {
  //     console.error("Failed to decode Base64 string: ", error);
  //   }
  // };

  // Function to convert UTC to UAE time
  const convertUTCtoUAE = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const uaeDate = utcDate.toLocaleString("en-US", {
      timeZone: "Asia/Dubai",
      hour12: false,
    });
    return uaeDate.replace(",", "");
  };

  return (
    <>
      <Modal
        centered
        open={open}
        onCancel={handleClose}
        width={rem(808)}
        className="edit-popup"
      >
        <div className="user-popup">
          <div className="flexBox popup sales-popup">
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"Email From"}
                type={"text"}
                placeholder={""}
                fieldData={userData.sales_person_name.toLowerCase()}
                readOnly={true}
                styles={{ textTransform: "capitalize" }}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"Email"}
                type={"text"}
                placeholder={""}
                fieldData={userData.from_email}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <InputField
                labelName={"From Date/Time"}
                type={"text"}
                placeholder={""}
                fieldData={convertUTCtoUAE(userData.from_email_time)}
                readOnly={true}
              />
            </div>
            <div style={{ width: rem(300) }}>
              <TextField
                labelName={"Mail Subject"}
                type="text"
                fieldData={userData?.from_subject}
                readOnly={true}
                height={rem(60)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <TextField
                labelName={"Mail Body"}
                type="text"
                fieldData={userData?.plain_text}
                readOnly={true}
                height={rem(240)}
              />
            </div>
          </div>
        </div>
        <Modal
          className="feedback-pdf"
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
        >
          {modalContent}
        </Modal>
      </Modal>
    </>
  );
};

export default EditPopup;
