import React from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import material_symbols_download from "../../Assets/QuizMobile/material_symbols_download.svg";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

// Custom plugin to display text inside the doughnut
const centerTextPlugin = {
  id: "centerText",
  beforeDraw: function (chart) {
    const width = chart.width;
    const height = chart.height;
    const ctx = chart.ctx;
    ctx.restore();
    const fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    const text = "5"; // Your dynamic text here
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;
    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

export default function RankingPage() {
  const data = {
    datasets: [
      {
        data: [2666, 709, 689],
        backgroundColor: ["#1E6641", "#558B70", "#CEDDD5"],
        hoverBackgroundColor: ["#1E6641", "#558B70", "#CEDDD5"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend, as we are customizing it
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((sum, value) => sum + value, 0);
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${currentValue} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "60%", // Controls the size of the hole in the middle
  };

  const rankings = [
    { rank: 1, name: "John Doe", score: 100 },
    { rank: 2, name: "Jane Smith", score: 90 },
    { rank: 3, name: "Sam Green", score: 80 },
    { rank: 4, name: "Sam Green", score: 80 },
    { rank: 5, name: "Sam Green", score: 80 },
  ];

  return (
    <div className="ranking_page">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
        <img src={material_symbols_download} alt="error" />
      </div>
      <div className="score_bar">
        <p className="ranki">Welding Quiz 1 Score</p>
        <div>
          <Doughnut
            data={data}
            options={options}
            plugins={[centerTextPlugin]}
          />
        </div>
      </div>
      <div className="outsider">
        <div className="resultBar">
          <div className="rs_div">
            <p className="dot"></p>
            Correct
          </div>
          <div className="rs_div">
            <p className="dot"></p>
            Incorrect
          </div>
          <div className="rs_div">
            <p className="dot"></p>
            Unattenpted
          </div>
        </div>
      </div>
      <div className="table_container">
        <p className="sub_rank">Ranking</p>
        <div className="ranking_table">
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {rankings.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.rank}</td>
                  <td>{entry.name}</td>
                  <td>{entry.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="btn_quiz">
        <button className="quizBtn">Try Another Quiz</button>
      </div>
    </div>
  );
}
