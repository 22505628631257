import React from "react";
import "./SalesHome.scss";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler,
} from "chart.js";
import { Dropdown, Space } from "antd";

import { rem } from "../../../Components/Rem_func";
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Filler
);

const SalesTracker = ({ access }) => {
  const data = {
    labels: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
    datasets: [
      {
        // label: "Sales",
        data: [85, 70, 108, 95, 110, 90],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#1E6641",
        borderWidth: 4,
        pointBorderColor: "#1E6641",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#1E6641",
        tension: 0.4, // Curved lines
        pointRadius: 6, // Increase the size of the pointer
        pointHoverRadius: 6, // Increase size on hover
      },
      {
        // label: "Revenue",
        data: [20, 40, 30, 50, 25, 50],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#97D3B5",
        borderWidth: 4,
        pointBorderColor: "#97D3B5",
        pointBackgroundColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#97D3B5",
        tension: 0.4, // Curved lines
        pointRadius: 6, // Increase the size of the pointer
        pointHoverRadius: 6, // Increase size on hover
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `$${context.raw}.00`;
          },
        },
      },
      legend: {
        display: false, // Remove the legend labels
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Remove grid lines on the Y-axis
        },
        ticks: {
          display: false, // Remove tick marks on the Y-axis
        },
        border: {
          display: false, // Hide the y-axis line
        },
      },
      x: {
        grid: {
          display: false, // Remove grid lines on the X-axis
        },
        ticks: {
          color: "#C0C0C0", // Color of tick labels on the X-axis
        },
        border: {
          display: false, // Hide the x-axis line
        },
      },
    },
  };
  const items = [
    {
      key: "1",
      label: <span>Dubai</span>,
    },
    {
      key: "2",
      label: <span>Abudhabi</span>,
    },
    {
      key: "3",
      label: <span>Oman</span>,
    },
  ];
  return (
    <div className="sales-home">
      <div className="df space-between">
        <p className="userM" style={{ paddingBottom: rem(5) }}>
          Sales Tracker
        </p>
        <div className="df" style={{ gap: rem(19) }}>
          {["admin", "Manager"].includes(access?.role_name) && (
            <div className="branch">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Space>
                  Select Team
                  {/* <DownOutlined /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(19)}
                    height={rem(19)}
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          {access.role_name === "admin" && (
            <div className="branch">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Space>
                  Select Branch
                  {/* <DownOutlined /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={rem(19)}
                    height={rem(19)}
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M13.459 7.91667L9.50065 11.875L5.54232 7.91667L13.459 7.91667Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </Space>
              </Dropdown>
            </div>
          )}
          <div className="bar-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(24)}
              height={rem(24)}
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_931_4705)">
                <path
                  d="M6.4 9.2H6.6C7.37 9.2 8 9.83 8 10.6V17.6C8 18.37 7.37 19 6.6 19H6.4C5.63 19 5 18.37 5 17.6V10.6C5 9.83 5.63 9.2 6.4 9.2ZM12 5C12.77 5 13.4 5.63 13.4 6.4V17.6C13.4 18.37 12.77 19 12 19C11.23 19 10.6 18.37 10.6 17.6V6.4C10.6 5.63 11.23 5 12 5ZM17.6 13C18.37 13 19 13.63 19 14.4V17.6C19 18.37 18.37 19 17.6 19C16.83 19 16.2 18.37 16.2 17.6V14.4C16.2 13.63 16.83 13 17.6 13Z"
                  fill="#1E6641"
                />
              </g>
              <defs>
                <clipPath id="clip0_931_4705">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div style={{ width: "80%", height: rem(216), margin: "auto" }}>
        <Line data={data} options={options} height={200} />
      </div>

      <div
        className="sales-bottom df space-between"
        style={{ paddingBottom: rem(21) }}
      >
        <div>
          <div className="salespercentage">$37.5k</div>
          <div className="df" style={{ gap: rem(8) }}>
            <div className="total">Total Sales</div>
            <div className="df">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={rem(21)}
                height={rem(19)}
                viewBox="0 0 21 19"
                fill="none"
              >
                <g clipPath="url(#clip0_931_4712)">
                  <path
                    d="M6.56445 11.351L10.757 7.61182L14.9495 11.351H6.56445Z"
                    fill="#8FDBB5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_931_4712">
                    <rect
                      width="20.1242"
                      height="17.948"
                      fill="white"
                      transform="translate(0.695312 0.881348)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className="qul">+2.45%</div>
            </div>
          </div>
        </div>

        <div className="qul df" style={{ gap: rem(6) }}>
          <div className="tick">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(12)}
              height={rem(12)}
              viewBox="0 0 12 12"
              fill="none"
            >
              <g clipPath="url(#clip0_931_4718)">
                <path
                  d="M4.81935 7.50603L3.21518 5.90187C3.03643 5.72312 2.75227 5.72312 2.57352 5.90187C2.39477 6.08062 2.39477 6.36478 2.57352 6.54353L4.49393 8.46395C4.67268 8.6427 4.96143 8.6427 5.14018 8.46395L9.99851 3.6102C10.1773 3.43145 10.1773 3.14729 9.99851 2.96854C9.81976 2.78979 9.5356 2.78979 9.35685 2.96854L4.81935 7.50603Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_931_4718">
                  <rect
                    width="11"
                    height="11"
                    fill="white"
                    transform="translate(0.695312 0.0810547)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          On track
        </div>
      </div>
      <div className="df  justify-center ">
        <div className="flexBox tracker">
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#1E6641" />
            </svg>
            <div>
              <p className="enq">Enquiries</p>
              <p className="per">63%</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#FFBC10" />
            </svg>
            <div>
              <p className="enq">Awaiting Order</p>
              <p className="per">25%</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#63C89B" />
            </svg>
            <div>
              <p className="enq">Order Placed</p>
              <p className="per">10%</p>
            </div>
          </div>
          <div className="com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={rem(8)}
              height={rem(8)}
              viewBox="0 0 8 8"
              fill="none"
              style={{ marginTop: rem(5), marginRight: rem(8) }}
            >
              <circle cx="4" cy="4" r="4" fill="#EB5757" />
            </svg>
            <div>
              <p className="enq">Awaiting Quotes</p>
              <p className="per">10%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesTracker;
