import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";

const SalesTable = ({ userList, filteredData, formatNumber }) => {
  const access = useSelector(selectUserData); // Get user access role
  const [employeesArray, setEmployeesArray] = useState([]);

  const roleTypes = useCallback(() => {
    let filteredEmployees = [];

    // No specific filters applied, show all employees based on role
    if (access.role_name === "admin") {
      if (filteredData?.managers?.length > 0) {
        // Filter for selected managers
        const selectedManagers = userList.filter((manager) =>
          filteredData?.managers.includes(manager.manager)
        );

        if (filteredData?.teamleads?.length > 0) {
          // Further filter by selected team leads under the selected managers
          const selectedTeamLeads = selectedManagers.flatMap((manager) =>
            manager?.team_leads?.filter((tl) =>
              filteredData?.teamleads.includes(tl.team_lead)
            )
          );

          if (filteredData?.employees?.length > 0) {
            // Further filter by selected employees under the selected team leads

            filteredEmployees = selectedTeamLeads.flatMap((tl) =>
              tl.employees.filter((emp) =>
                filteredData?.employees.includes(emp.employee)
              )
            );
          } else {
            // If no specific employees are selected, return all employees under the selected team leads
            filteredEmployees = selectedTeamLeads.flatMap((tl) => tl.employees);
          }
        } else if (filteredData?.employees?.length > 0) {
          // If team leads are not selected, filter employees directly under the selected managers
          filteredEmployees = selectedManagers.flatMap((manager) =>
            manager?.team_leads.flatMap((tl) =>
              tl.employees.filter((emp) =>
                filteredData?.employees.includes(emp.employee)
              )
            )
          );
        } else {
          // If no team leads or employees are selected, show all employees under the selected managers
          filteredEmployees = selectedManagers.flatMap((manager) =>
            manager?.team_leads?.flatMap((tl) => tl.employees)
          );
        }
      } else if (filteredData?.teamleads?.length > 0) {
        // If no managers are selected but team leads are, filter by team leads
        const selectedTeamLeads = userList?.flatMap((manager) =>
          manager?.team_leads?.filter((tl) =>
            filteredData?.teamleads.includes(tl.team_lead)
          )
        );

        // If specific employees are selected, filter employees under selected team leads
        if (filteredData?.employees?.length > 0) {
          filteredEmployees = selectedTeamLeads.flatMap((tl) =>
            tl.employees.filter((emp) =>
              filteredData?.employees.includes(emp.employee)
            )
          );
        } else {
          // If no specific employees are selected, return all employees under the selected team leads
          filteredEmployees = selectedTeamLeads?.flatMap((tl) => tl.employees);
        }
      } else if (filteredData?.employees?.length > 0) {
        // Directly filter employees

        const selectedEmployees = userList.flatMap((manager) =>
          manager?.team_leads?.flatMap((teamLead) =>
            teamLead?.employees?.filter((employee) =>
              filteredData?.employees.includes(employee?.employee)
            )
          )
        );

        filteredEmployees = selectedEmployees;
      } else {
        filteredEmployees = userList.flatMap((manager) =>
          manager.team_leads.flatMap((tl) => tl.employees)
        );
      }
    } else if (access.role_name === "Manager") {
      // Check if specific team leads are selected in the filter
      if (filteredData?.teamleads?.length > 0) {
        // If specific employees are selected, filter those employees under the selected team leads
        if (filteredData?.employees?.length > 0) {
          filteredEmployees = userList[0].team_leads
            .filter((emp) => filteredData?.teamleads?.includes(emp.team_lead))
            .flatMap((emp) => emp.employees)
            .filter((emp) => filteredData.employees.includes(emp.employee));
        } else {
          // If no employees are selected, show all employees under the selected team leads
          filteredEmployees = userList[0].team_leads
            .filter((emp) => filteredData?.teamleads?.includes(emp.team_lead))
            .flatMap((emp) => emp.employees);
        }
      } else if (filteredData?.employees?.length > 0) {
        // If no team leads are selected but employees are, filter employees directly under the manager
        filteredEmployees = userList[0].team_leads
          .flatMap((manager) => manager.employees)

          .filter((emp) => filteredData?.employees.includes(emp.employee));
        // );
      } else {
        // If no specific filters are applied, show all employees under all team leads for the manager
        filteredEmployees = userList[0]?.team_leads.flatMap(
          (manager) => manager?.employees
        );
      }

      setEmployeesArray(filteredEmployees);
    } else if (access.role_name === "Teamlead") {
      if (filteredData?.employees?.length > 0) {
        filteredEmployees = userList[0]?.employees.filter((employee) =>
          filteredData?.employees?.includes(employee.employee)
        );
      } else {
        filteredEmployees = userList[0]?.employees.flatMap(
          (employee) => employee || []
        );
      }
    } else if (access.role_name === "employee") {
      filteredEmployees = userList?.map((emp) => emp);
    }

    setEmployeesArray(filteredEmployees);
  }, [access.role_name, userList, filteredData]);

  useEffect(() => {
    roleTypes();
  }, [roleTypes]);

  return (
    <div className="quote_home sales-dashboard-table">
      <div className="quote-home-table">
        <table>
          <thead>
            <tr>
              <th className="tl">Sales Team Member</th>
              <th>Enquiries</th>
              <th>Conversion %(Enquiries)</th>
              <th>Conversion %(Awarded)</th>
              <th> Awarded-AED</th>
              <th> Awarded-USD</th>
              <th> Awarded-OMR</th>
            </tr>
          </thead>
          <tbody>
            {employeesArray?.length ? (
              employeesArray?.map((employee, index) => (
                <tr key={index}>
                  <td className="textCaps tl">
                    {employee?.employee?.toLowerCase() || "DataNR"}
                  </td>
                  <td>{employee?.ackmail_count || "DataNR"}</td>
                  <td>
                    {`${employee?.conversion_percent?.toFixed(2)}%` || "DataNR"}
                  </td>
                  <td>
                    {`${employee?.conversion_percent?.toFixed(2)}%` || "DataNR"}
                  </td>
                  <td>
                    {formatNumber(employee?.awarded_aed_value?.toFixed(2)) ||
                      "DataNR"}
                  </td>

                  <td>DataNR</td>
                  <td>DataNR</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No Sales Person found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalesTable;
