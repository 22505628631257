import React, { useState, useRef, useCallback } from "react";
import Button from "../../Components/Button/Button";
import deleteicon from "../../Assets/HeatNumber/deleteIcon.svg";
import manufacturing from "../../Assets/Quality/manufacturing.svg";
import company from "../../Assets/Quality/company.svg";
import factory from "../../Assets/Quality/factory.svg";
import img19 from "./../../Assets/Quality/image19.svg";
import img20 from "./../../Assets/Quality/image20.svg";
import gasLabel from "./../../Assets/Quality/image4.svg";

import "./Quality.scss";
import SnackBar from "../../Components/SnackBar";
import _http from "../../Utils/Api/_http";
import SelectSearch from "../../Components/SelectFields/SelectField";
import InputField from "../../Components/InputField/InputField";
import TextField from "../../Components/TextField/TextField";
// import Qualityyes from "../../Components/Svg/Quality_yes";
// import Qualityno from "../../Components/Svg/Quality_no";
import Qualityupload from "../../Components/Svg/Quality_upload";
import { rem } from "../../Components/Rem_func";
import QualityPopUp from "./QualityPopUp";
import QualityOpen from "./QualityOpen";

const HeatNumber = () => {
  const [heatNumbers, setHeatNumbers] = useState([""]);
  const [result, setResult] = useState([]);
  const [file, setFile] = useState();
  const [files, setFiles] = useState(false);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [MTCdata, setMTCdata] = useState({
    gasket: "",
    company_name: "",
    po_no: "",
    Cert_no: "",
    doc_no: "",
    mtc_date: "",
    desp: "",
  });

  const [erring, setErring] = useState({});
  const [mtc, setMtc] = useState(false);
  const [heat, setHeat] = useState(false);
  const [toast, setToast] = useState({ submit: false, error: false });
  const [errors, setError] = useState("");

  const [inputFields, setInputFields] = useState([
    { item_no: "", size: "", quant: "" },
  ]);

  const [apiInProgress, setApiInProgress] = useState(false);

  // Call this when API starts
  const startApiCall = () => {
    setApiInProgress(true);
  };

  // Call this when API ends
  const endApiCall = () => {
    setApiInProgress(false);
  };

  const addHeatBox = useCallback(() => {
    setHeatNumbers([...heatNumbers, ""]);
  }, [heatNumbers]);

  const additemBox = () => {
    setInputFields([...inputFields, { item_no: "", size: "", quant: "" }]);
  };

  const handleHeatChange = (index, value) => {
    // Allow only digits
    const onlyNumbers = value.replace(/\D/g, "");

    const newHeatNumbers = [...heatNumbers];
    newHeatNumbers[index] = onlyNumbers;
    setHeatNumbers(newHeatNumbers);
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
  };

  const deleteRow = useCallback(
    (index) => {
      const newHeatNumbers = [...heatNumbers];
      newHeatNumbers.splice(index, 1);
      setHeatNumbers(newHeatNumbers);
    },
    [heatNumbers]
  );

  const deleteItem = useCallback(
    (index) => {
      const newItems = [...inputFields];
      newItems.splice(index, 1);
      setInputFields(newItems);
    },
    [inputFields]
  );

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const maxDate = getCurrentDate();

  // useEffect(() => {
  //   if (MTCdata.gasket !== "") {
  //     setHeatNumbers([""]);
  //   }
  // }, [MTCdata.gasket]);
  const handletoast = () => {
    setToast({ submit: false, error: false });
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const fetchData = useCallback(async () => {
    const nonEmptyHeatNumbers = heatNumbers.filter(
      (heat) => heat.trim() !== ""
    );

    if (nonEmptyHeatNumbers.length === 0) {
      setError("Please add Heat Number");
      setToast({ error: true });
      setHeat(false);
      return;
    } else {
      setHeatNumbers(nonEmptyHeatNumbers);
      setHeat(true);
      startApiCall();

      try {
        const response = await _http.post(
          `https://autodesk.dtskill.com/heat_number_check`,
          {
            heat_numbers: nonEmptyHeatNumbers,
          }
        );
        setResult(response.data);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
    }
    setHeat(false);
    endApiCall();
  }, [heatNumbers]);

  const uploadPdf = async () => {
    if (!file) {
      setToast({ error: true });
      setError("Please select a file");
    } else {
      setFiles(true);
      try {
        const formData = new FormData();
        formData.append("file", file[0]);
        const response = await _http.post(`/api/upload_pdf`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setFile();
        setToast(
          (response.data.message && { submit: true }) ||
            (response.data.error && { error: true })
        );
        setError(response.data.message || response.data.error);
      } catch (error) {
        setToast({ error: true });
        setError(error.message);
      }
      setFiles(false);
    }
  };

  const generateMTC = async () => {
    const requiredFields = [
      "company_name",
      "po_no",
      "Cert_no",
      "doc_no",
      "mtc_date",
      "desp",
      "gasket",
    ];

    const emptyFields = [];

    // Check the required fields in MTCdata
    const newErring = { ...erring };
    requiredFields.forEach((field) => {
      if (!MTCdata[field] || MTCdata[field].toString().trim() === "") {
        newErring[`${field}_error`] = true;
        emptyFields.push(field);
      } else {
        newErring[`${field}_error`] = false;
      }
    });

    // Check each item in inputFields
    inputFields.forEach((item, index) => {
      if (!item.item_no.trim()) {
        newErring[`item_no_error_${index}`] = true;
        emptyFields.push(`item_no_${index}`);
      } else {
        newErring[`item_no_error_${index}`] = false;
      }

      if (!item.size.trim()) {
        newErring[`size_error_${index}`] = true;
        emptyFields.push(`size_${index}`);
      } else {
        newErring[`size_error_${index}`] = false;
      }

      if (!item.quant.trim()) {
        newErring[`quant_error_${index}`] = true;
        emptyFields.push(`quant_${index}`);
      } else {
        newErring[`quant_error_${index}`] = false;
      }
    });

    setErring(newErring);

    // Focus on all empty fields sequentially
    emptyFields.forEach((fieldId) => {
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.focus();
      }
    });

    if (emptyFields.length > 0) {
      setError("Please fill the input fields");
      setToast({ error: true });
      return;
    }

    const nonEmptyHeatNumbers = heatNumbers.filter(
      (heat) => heat.trim() !== ""
    );

    if (nonEmptyHeatNumbers.length === 0) {
      setError("Please add Heat Number");
      setToast({ error: true });
      setHeat(false);
      return;
    }

    // Check if any heat number has a status of 'no'
    const noHeatNumbers = heatNumbers.filter((heat) => {
      const resultIndex = result.findIndex((item) => item.number === heat);
      return resultIndex !== -1 && result[resultIndex].status === "no";
    });

    // If there are heat numbers with status 'no', set toast and error
    if (noHeatNumbers.length > 0) {
      setError("Please upload a PDF for the heat number(s) with status 'no'.");
      setToast({ error: true });
      return;
    }

    setMtc(true);
    startApiCall();
    try {
      const response = await _http.post(
        `https://autodesk.dtskill.com/doc_creation`,
        {
          company_name: MTCdata.company_name,
          gasket: MTCdata.gasket,
          heat_no: nonEmptyHeatNumbers,
          po_no: MTCdata.po_no,
          Cert_no: MTCdata.Cert_no,
          doc_no: MTCdata.doc_no,
          mtc_date: MTCdata.mtc_date,
          desp: MTCdata.desp,
          item: inputFields,
        },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${MTCdata.company_name}.docx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      // const url = URL.createObjectURL(blob);

      // sessionStorage.setItem("url", url);
      // // Open the generated document in a new tab/window
      // const newTab = window.open("/fileviewer", "_blank");

      // // Optionally focus the new tab
      // if (newTab) {
      //   newTab.focus();
      // }
      setHeatNumbers([""]);
      setMTCdata({
        gasket: "",
        company_name: "",
        po_no: "",
        Cert_no: "",
        doc_no: "",
        mtc_date: "",
        desp: "",
      });
      setInputFields([{ item_no: "", size: "", quant: "" }]);
      setError("File generated successfully");
      setToast({ submit: true });
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }

    setMtc(false);
    endApiCall();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log("Dropped files:", files);
  };

  // array to map images
  const companyName = [factory, manufacturing, company];

  // to display text
  // const companyText = [
  //   "Spira Power (Gasket Manufacturing LLC)",
  //   "Spira Power (Gasket Manufacturing LLC)",
  //   "Spira Power (Gasket Manufacturing LLC)",
  // ];

  const inputDatas = [
    {
      subHead: "Customer",
      subValue: "company_name",
      placeholder: "Enter Company Name",
      pattern: "onlyAlpha",
    },
    {
      subHead: "PO Number",
      subValue: "po_no",
      placeholder: "Enter PO Number",
      pattern: "numberOnly",
    },
    {
      subHead: "Certificate Number",
      subValue: "Cert_no",
      placeholder: "Enter Certificate Number",
      pattern: "numberOnly",
    },
    // {
    //   subHead: "Spira Product",
    //   subValue: "pro_no",
    //   placeholder: "Enter Product Number",
    // },
    {
      subHead: "Document Number",
      subValue: "doc_no",
      placeholder: "Enter Document Number",
      pattern: "numberOnly",
    },
    { subHead: "Date", subValue: "mtc_date", type: "date", maxDate: maxDate },
  ];

  const options1 = [
    "Double-jacketed gaskets",
    "Kammprofile gaskets",
    "RTJ gaskets (Ring Type Joint)",
    "Plug gaskets",
    "Insulation kits",
    "Flexible Expanded Graphite SWG",
    "Mica SWG",
    "E-PTFE SWG(expanded polytetrafluoroethylene)",
  ];

  const handleSelectChange = (name, value) => {
    setMTCdata({ ...MTCdata, [name]: value });
  };

  const imgHandler = (index) => {
    setSelectedIndex(index);
  };

  const openHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <section className="quality">
      <div className="container1">
        <div className="df flexColumn align-start header w100">
          <div className="title">Material Test Certificate</div>
          {/* <div className="main_container"> */}
          {/* to display images */}
          <div className="company_logos ">
            {companyName.map((x, index) => {
              return (
                <div key={index}>
                  <button
                    className={`img_sec ${
                      selectedIndex === index ? "active" : ""
                    }`}
                    onClick={() => imgHandler(index)}
                  >
                    <img src={x} alt="error" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="heat-search flexBox">
          <div style={{ width: rem(256) }}>
            <SelectSearch
              label="Gasket Material"
              name="item"
              Placeholder="Select option"
              options={options1}
              value={MTCdata.gasket}
              onChange={(value) => handleSelectChange("gasket", value)}
              hasError={erring.gasket_error}
              readOnly={mtc}
            />
          </div>
          {inputDatas.map((x, index) => {
            return (
              <div key={index} style={{ width: rem(256) }}>
                <InputField
                  label={x.subValue}
                  labelName={x.subHead}
                  type={x.type || "text"}
                  placeholder={x.placeholder}
                  fieldData={MTCdata[x.subValue]}
                  setFormData={setMTCdata}
                  maxLength={x.maxDate ? x.maxDate : 30}
                  hasError={erring[`${x.subValue}_error`]}
                  readOnly={mtc}
                  pattern={x.pattern ? x.pattern : null}
                />
              </div>
            );
          })}
        </div>

        <div style={{ display: "flex", gap: rem(24) }}>
          <div>
            <div className="df align-end heat-numbers">
              <div>
                <div className="sub-head">Heat Number</div>
                {heatNumbers?.map((heat, index) => {
                  const resultIndex = result.findIndex(
                    (item) => item.number === heat
                  );
                  const resultStatus =
                    resultIndex !== -1 && result[resultIndex].status;

                  return (
                    <div
                      className="flexBox align-start flexColumn"
                      style={{ paddingTop: rem(10) }}
                      key={index}
                    >
                      <div
                        className={`input-filter heat ${
                          resultStatus === "yes"
                            ? "true-heat"
                            : resultStatus === "no"
                            ? "false-heat"
                            : ""
                        }`}
                        style={{
                          width: rem(256),
                          position: "relative",
                          paddingRight: rem(10),
                        }}
                      >
                        <input
                          style={{ border: "none" }}
                          placeholder="Search Heat Number"
                          autoSave="true"
                          value={heat}
                          onChange={(e) =>
                            handleHeatChange(index, e.target.value)
                          }
                          maxLength={10}
                          readOnly={apiInProgress || mtc === heat}
                        />

                        {/* {resultStatus === "yes" && (
                          <div style={{ width: "10%" }}>
                            <Qualityyes />
                          </div>
                        )}

                        {resultStatus === "no" && (
                          <div style={{ width: "10%" }}>
                            <Qualityno />
                          </div>
                        )} */}
                        {heatNumbers.length > 1 && (
                          <img
                            src={deleteicon}
                            alt="img"
                            onClick={() => deleteRow(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>

                      {resultStatus === "no" && (
                        <span className="heat-comment">
                          Heat number unavailable,upload the PDF below
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <div>
                {
                  <button
                    onClick={addHeatBox}
                    className="addBtn"
                    style={{ border: "none" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={rem(20)}
                      height={rem(20)}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <rect
                        x="0.714286"
                        y="0.714286"
                        width="18.5714"
                        height="18.5714"
                        rx="9.28571"
                        stroke="#A2B7AE"
                        strokeWidth="1.42857"
                      />
                      <path
                        d="M9.38734 10.6123H5.71387V9.38783H9.38734V5.71436H10.6118V9.38783H14.2853V10.6123H10.6118V14.2858H9.38734V10.6123Z"
                        fill="#1E6641"
                      />
                    </svg>
                    {/* <img src={Add} alt="icon" /> */}
                  </button>
                }
              </div>
            </div>
            <div style={{ marginTop: rem(12) }}>
              <Button
                onclick={fetchData}
                btnname={heat ? "Checking..." : "Verify in DB"}
                cl="#1e6641"
                bg="#fff"
                border="1px solid #1e6641"
                box_s={"none"}
                disabled={heat}
                br={4}
                fs={20}
                width={141}
                height={41}
              />
            </div>

            <div className={`file-upload`}>
              <div
                // className={`file-upload`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDrop={handleDrop}
                onClick={handleFileUpload}
              >
                {/* drap and drop svg img */}
                <Qualityupload />

                <p>Drag and drop or</p>
                <p>select a file to upload</p>
              </div>
              <div className="btn_upload">
                <Button
                  onclick={uploadPdf}
                  btnname={files ? "Uploading..." : "Upload Heat Number"}
                  height={31}
                  fs={12}
                  fw={500}
                  width={155}
                  disabled={files}
                  br={4}
                />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  setFile(e.target.files);
                }}
              />
            </div>
          </div>

          <div>
            <div className="description-input">
              <TextField
                label={`desp`}
                labelName="Description"
                type="text"
                fieldData={MTCdata.desp}
                setFormData={setMTCdata}
                hasError={erring[`desp_error`]}
                readOnly={mtc}
              />
            </div>

            <div className="item_container">
              {inputFields.map((inputField, index) => (
                <div className="df mai" key={index}>
                  <div className="inp_sec">
                    <div style={{ width: rem(256) }}>
                      <InputField
                        label={`item_no_${index}`}
                        labelName="Item Number:"
                        type="text"
                        fieldData={inputField.item_no}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "item_no", value: data },
                          })
                        }
                        pattern={"numberOnly"}
                        hasError={erring[`item_no_error_${index}`]}
                        onChange={true}
                        maxLength={10}
                        placeholder="Enter Item Number"
                        readOnly={mtc}
                      />
                    </div>
                    <div style={{ width: rem(256) }}>
                      <InputField
                        label={`size_${index}`}
                        labelName="Size:"
                        // type="number"
                        fieldData={inputField.size}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "size", value: data },
                          })
                        }
                        hasError={erring[`size_error_${index}`]}
                        onChange={true}
                        pattern={"numberWithFloat"}
                        maxLength={10}
                        placeholder="Enter Size"
                        readOnly={mtc}
                      />
                    </div>
                    <div style={{ width: rem(256) }}>
                      <InputField
                        label={`quant_${index}`}
                        labelName="Quantity:"
                        // type="number"
                        fieldData={inputField.quant}
                        setFormData={(data) =>
                          handleItemChange(index, {
                            target: { name: "quant", value: data },
                          })
                        }
                        hasError={erring[`quant_error_${index}`]}
                        onChange={true}
                        pattern={"numberOnly"}
                        maxLength={10}
                        placeholder="Enter Quantity"
                        readOnly={mtc}
                      />
                    </div>
                  </div>
                  <div
                    className="flexBox  justify-end"
                    style={{ gap: rem(10) }}
                  >
                    {inputFields.length > 1 && (
                      <div
                        className="addBtn df justify-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteItem(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(10)}
                          height={rem(12)}
                          viewBox="0 0 10 12"
                          fill="none"
                        >
                          <path
                            d="M2.41656 11.2501C2.09572 11.2501 1.82116 11.136 1.59287 10.9077C1.36458 10.6794 1.25025 10.4046 1.24986 10.0834V2.49982H0.666504V1.33311H3.58327V0.749756H7.08339V1.33311H10.0002V2.49982H9.41681V10.0834C9.41681 10.4043 9.30267 10.679 9.07438 10.9077C8.8461 11.1364 8.57134 11.2505 8.2501 11.2501H2.41656ZM8.2501 2.49982H2.41656V10.0834H8.2501V2.49982ZM3.58327 8.91671H4.74998V3.66652H3.58327V8.91671ZM5.91669 8.91671H7.08339V3.66652H5.91669V8.91671Z"
                            fill="#1E6641"
                          />
                        </svg>
                      </div>
                    )}
                    {index === inputFields.length - 1 && (
                      <button
                        onClick={additemBox}
                        className="addBtn bt2"
                        style={{ border: "none" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={rem(20)}
                          height={rem(20)}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <rect
                            x="0.714286"
                            y="0.714286"
                            width="18.5714"
                            height="18.5714"
                            rx="9.28571"
                            stroke="#A2B7AE"
                            strokeWidth="1.42857"
                          />
                          <path
                            d="M9.38734 10.6123H5.71387V9.38783H9.38734V5.71436H10.6118V9.38783H14.2853V10.6123H10.6118V14.2858H9.38734V10.6123Z"
                            fill="#1E6641"
                          />
                        </svg>
                        {/* <img src={Add} alt="icon" /> */}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* another component */}

        <div className="w100">
          <QualityPopUp />

          <div className="quality-footer">
            <div className="part1">
              <img
                src={img19}
                alt="error"
                style={{ width: rem(254), height: rem(84) }}
              />
              <img
                src={img20}
                alt="error"
                style={{ width: rem(300), height: rem(70) }}
              />
              <img
                src={gasLabel}
                alt="error"
                style={{ width: rem(360), height: rem(84) }}
              />
            </div>
            <div className="part2">
              Manufacturer & Supplier of Metallic & Non-Metallic industrial
              Gaskets including Non-Asbestos-Spiral Wound-Metal Jacketed Ring
              Joint-Cam-profile-Insulation Kit Gland Rubber Packings-Expansion
              Joints-Mechanical Seals-Thermal insulation Products-Rubber
              Products and Fabric Bellows- Industrial Flexible Hoses-Water-jet
              Cutting Services s-Laser Cutting Services
            </div>
            <div className="part3">
              <Button
                btnname={"Preview"}
                onclick={openHandler}
                height={49}
                width={256}
                fs={20}
                br={4}
                disabled={mtc}
                bg="#fff"
                cl="#1E6641"
                border="1px solid #1E6641"
              />
              <Button
                btnname={mtc ? "Generating..." : "Generate MTC"}
                onclick={generateMTC}
                height={49}
                width={256}
                fs={20}
                br={4}
                disabled={mtc}
              />
            </div>
          </div>
          {/* </div> */}

          {open && (
            <QualityOpen
              openHandler={openHandler}
              closeHandler={closeHandler}
              mtc={mtc}
              generateMTC={generateMTC}
              open={open}
            />
          )}
        </div>

        <SnackBar
          message={errors}
          severity={"error"}
          handleClose={handletoast}
          Open={toast?.error}
        />
        <SnackBar
          message={errors}
          severity={"success"}
          handleClose={handletoast}
          Open={toast?.submit}
        />
      </div>
    </section>
  );
};

export default HeatNumber;
