import React, { useState, useEffect, useCallback } from "react";
import "../STcomponents/Filter/FilterEnquires.scss";
import { rem } from "../../../Components/Rem_func";
import Button from "../../../Components/Button/Button";
import SelectSearch from "../../../Components/SelectFields/SelectField";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../Redux/Reducers";
const DashBoardFilter = ({ hide, userList, filteredData, setFilteredData }) => {
  const access = useSelector(selectUserData);
  const [managersArray, setManagersArray] = useState([]);
  const [teamLeadsArray, setTeamLeadsArray] = useState([]);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [localData, setLocalData] = useState(filteredData);

  const roleTypes = useCallback(() => {
    let filteredManagers = [];
    let filteredTeamLeads = [];
    let filteredEmployees = [];

    if (access.role_name === "admin") {
      filteredManagers = userList?.map((item) => item.manager);
      setManagersArray(filteredManagers);
      if (localData.managers.length > 0) {
        const selectedManager = userList?.filter((item) =>
          localData.managers.includes(item.manager)
        );
        filteredTeamLeads = selectedManager
          .flatMap((item) => item?.team_leads)
          .flatMap((teamlead) => teamlead.team_lead);
        if (localData.teamleads.length > 0) {
          const selectedTeamLeads = selectedManager
            .flatMap((emp) => emp.team_leads)
            .filter((tl) => localData.teamleads.includes(tl.team_lead));

          filteredEmployees = selectedTeamLeads
            .flatMap((item) => item?.employees)
            .flatMap((emp) => emp.employee);
        } else {
          filteredEmployees = selectedManager
            .flatMap((tl) => tl.team_leads)
            .flatMap((emps) => emps.employees)
            .map((emp) => emp.employee);
        }
      } else {
        filteredTeamLeads = userList.flatMap((item) =>
          item.team_leads.map((tl) => tl.team_lead)
        );
        if (localData.teamleads.length > 0) {
          const selectedTeamLeads = userList
            .flatMap((emp) => emp.team_leads)
            .filter((tl) => localData.teamleads.includes(tl.team_lead));
          filteredEmployees = selectedTeamLeads
            .flatMap((emp) => emp.employees)
            .flatMap((emps) => emps.employee);
        } else {
          filteredEmployees = userList?.flatMap((item) =>
            item.team_leads.flatMap((tl) =>
              tl.employees.map((emp) => emp.employee)
            )
          );
        }
      }

      setTeamLeadsArray(filteredTeamLeads);

      setEmployeesArray(filteredEmployees);
    }

    if (access.role_name === "Manager") {
      // Filtering team leads based on selected managers
      if (localData?.teamleads?.length > 0) {
        // Find the team leads based on the selected team leads filter
        filteredTeamLeads = userList[0]?.team_leads?.filter((item) =>
          localData.teamleads.includes(item.team_lead)
        );

        filteredEmployees = filteredTeamLeads.flatMap((item) =>
          item.employees.map((emp) => emp.employee)
        );
      } else {
        filteredTeamLeads = userList[0]?.team_leads.flatMap(
          (manager) => manager.team_lead
        );

        setTeamLeadsArray(filteredTeamLeads);
        filteredEmployees = userList[0]?.team_leads.flatMap(
          (item) => item?.employees?.map((emp) => emp?.employee) // Extract employees
        );
      }

      setEmployeesArray(filteredEmployees);
    }

    // Teamlead Role Logic
    if (access.role_name === "Teamlead") {
      filteredEmployees = userList[0]?.employees?.map((item) => item.employee);
      setEmployeesArray(filteredEmployees);
    }
  }, [access.role_name, userList, localData]);

  useEffect(() => {
    roleTypes();
  }, [localData, roleTypes]);

  const handleSelectChange = (name, values) => {
    setLocalData((prevData) => ({
      ...prevData,
      [name]: values,
    }));
  };

  const handleFilter = () => {
    setFilteredData(localData);
    hide();
  };
  useEffect(() => {
    setLocalData(filteredData);
  }, [filteredData]);
  const ResetFilter = () => {
    const resetData = {
      managers: [],
      teamleads: [],
      employees: [],
    };

    setFilteredData(resetData);
    setLocalData(resetData);
  };

  return (
    <div className="filter-box">
      <div className="popover-title">Filter</div>
      <div className="filter-body">
        {access?.role_name === "admin" && (
          <SelectSearch
            options={managersArray}
            label="Managers"
            name="managers"
            Placeholder="Select Options"
            value={localData?.managers}
            onChange={(values) => handleSelectChange("managers", values)}
            height={36}
            readOnly={false}
            multiple={true}
          />
        )}

        {["admin", "Manager"].includes(access?.role_name) && (
          <SelectSearch
            options={teamLeadsArray}
            label="Teamlead"
            name="teamleads"
            Placeholder="Select Options"
            value={localData?.teamleads}
            onChange={(values) => handleSelectChange("teamleads", values)}
            height={36}
            readOnly={false}
            multiple={true}
          />
        )}

        {["admin", "Manager", "Teamlead"].includes(access?.role_name) && (
          <SelectSearch
            options={employeesArray}
            label="Employee"
            name="employees"
            Placeholder="Select Options"
            value={localData?.employees}
            onChange={(values) => handleSelectChange("employees", values)}
            height={36}
            readOnly={false}
            multiple={true}
            // dropdownPositionProp={"top"}
          />
        )}
      </div>
      <div className="filter-btn">
        <div>
          <Button
            onclick={ResetFilter}
            btnname={"Reset"}
            cl={"#1e6641"}
            br={4}
            bg={"#fff"}
            border={`${rem(1)} solid #1E6641`}
            box_s="none"
            fs={16}
          />
        </div>
        <div>
          <Button btnname={"Apply Now"} onclick={handleFilter} fs={16} br={4} />
        </div>
      </div>
    </div>
  );
};

export default DashBoardFilter;
