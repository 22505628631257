import React, { useState, useEffect, useCallback } from "react";
import "./SalesTracker.scss";
import EditPopup from "../STeditPopup/STeditPopup";
import EditPopup1 from "../STeditPopup/STeditPopup1";
import down from "../../../../Assets/EnquiresIcon/down.svg";
import filter from "../../../../Assets/EnquiresIcon/filter.svg";
import filteron from "../../../../Assets/EnquiresIcon/filteron.svg";
import greenDown from "../../../../Assets/EnquiresIcon/green_down.svg";
import _http from "../../../../Utils/Api/_http";
import FilterEnquires from "../Filter/FilterEnquires";
import { Popover, Space } from "antd";
import SnackBar from "../../../../Components/SnackBar";
import SizeChanger from "../../../../Components/PageSizeChanger/SizeChanger";
// import { rem } from "../../../../Components/Rem_func";
import { setdatacount } from "../../../../Redux/Reducers";
import { useDispatch } from "react-redux";
import EmailPopup from "../STeditPopup/EmailPopup";
import CrossIcon from "../../../../Components/Svg/CrossIcon";
import { rem } from "../../../../Components/Rem_func";
import searchIcon from "../../../../Assets/UserIcon/usersearchicon.svg";
import SalesHeader from "../../DashBoard/SalesHeader";
const SalesTracker = ({
  title,
  UserDataTable,
  api,
  AwaitingOrder,
  OrderPlaced,
  AwaitingQuotes,
  emailE,
  reports,
}) => {
  // State variables
  const [open, setOpen] = React.useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [openfilter, setOpenfilter] = useState(false);
  const [filteredData, setFilteredData] = useState({
    reference_number: "",
    sales_person_name: "",
    client_person_name: "",
    client_subject: "",
    client_email_from: "",
    client_email_to: "",
    sales_email_from: "",
    sales_email_to: "",
    ack_email_from: "",
    ack_email_to: "",
    quotation_email_from: "",
    quotation_email_to: "",
    order_date_from: "",
    order_date_to: "",
    min_quote_value: "",
    max_quote_value: "",
    min_order_value: "",
    max_order_value: "",
  });
  const [filters, setFilters] = useState({
    location: "",
    quick_filter: "Last week",
    year: "",
    quarter: "",
    date: "",
    currency: "",
  });
  const [searchData, setSeaarchData] = useState("");
  const [updateBtn, setUpdateBtn] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [toast, setToast] = useState({ delete: false, error: false });
  const [errors, setError] = useState("");
  const dispatch = useDispatch();

  // Function to handle closing snackbar
  const handletoast = () => {
    setToast({ error: false, delete: false });
  };

  const handleOrder = async (reference_number, updatedData, email) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("jsondata", JSON.stringify(updatedData));
    setUpdateBtn(true);
    try {
      await _http.put(`/api/update/${reference_number}`, formData);
      setError("Successfully updated");
      setToast({ delete: true });
      handleClose();
      fetchData();
    } catch (error) {
      setToast({ error: true });
      setError(error.message);
    }
    setUpdateBtn(false);
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        ...filters,
        year: filters.year.length ? filters.year.join(",") : "", // Convert year array to comma-separated string
        quarter: filters.quarter.length ? filters.quarter.join(",") : "", // Convert quarter array to comma-separated string
      };
      const response = await _http.get(api, { params });

      if (Array.isArray(response.data) && response.data.every(Array.isArray)) {
        const flattenedData = response.data.flat();

        setUsers(flattenedData.flat());
        dispatch(setdatacount(flattenedData?.flat()?.length));
      } else {
        setUsers(response.data);
        dispatch(setdatacount(response.data.length));
      }
    } catch (error) {
      setToast({ error: true });
      setError("Error fetching data:", error?.message);
      console.log(error);
    }
    setLoading(false);
  }, [api, dispatch, filters]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to handle opening user details
  const handleOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  // toast function for update
  const handleUpdateToast = (submit, error, message) => {
    setToast({
      submit: submit,
    });
    setToast({
      error: error,
    });
    setError(message);
  };

  // Function to handle closing user details
  const handleClose = () => setOpen(false);

  // Function to hide filter popover
  const hide = () => {
    setOpenfilter(false);
  };

  // Function to handle filter change
  const handleOpenChange = (newOpen) => {
    setOpenfilter(newOpen);
  };

  // Function to check if any filters are applied
  const checkIfFiltersAreApplied = (filters) => {
    return Object.values(filters).some((value) => value !== "");
  };

  // Update filterOn whenever filteredData changes
  useEffect(() => {
    setFilterOn(checkIfFiltersAreApplied(filteredData));
  }, [filteredData]);

  const renderEmptyOrValue = (text, TT, cln, Type) => {
    const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

    // Check if text matches the date-time format
    const isValidDateTime = dateTimePattern.test(text);

    // Return 'DataNR' if text is falsy
    if (!text) {
      return <span className="empty">DataNR</span>;
    }

    // Handle date or time types
    if (Type === "date" || Type === "time") {
      if (isValidDateTime) {
        const [datePart, timePart] = text.split(" ");
        return <span>{Type === "date" ? datePart : timePart}</span>;
      } else {
        return <span>MismatchF</span>; // Return this if the format does not match
      }
    }

    // Default case: Return the text with optional class and capitalization
    return (
      <span
        className={`${cln} w100 overflowData`}
        style={{ textTransform: TT ? "capitalize" : "none" }}
      >
        {text}
      </span>
    );
  };
  const DateSorter = (dataIndex) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/; // YYYY-MM-DD HH:MM:SS format

    return (a, b) => {
      // Get the date strings from the respective dataIndex
      const dateA = a[dataIndex];
      const dateB = b[dataIndex];

      // Check if the date format is valid (either datePattern or dateTimePattern)
      const isValidDateA =
        datePattern.test(dateA) || dateTimePattern.test(dateA);
      const isValidDateB =
        datePattern.test(dateB) || dateTimePattern.test(dateB);

      if (isValidDateA && isValidDateB) {
        // Both dates are valid, compare them as Date objects
        return new Date(dateA) - new Date(dateB);
      } else if (isValidDateA) {
        // Only dateA is valid, so it comes before
        return 1;
      } else if (isValidDateB) {
        // Only dateB is valid, so it comes before
        return -1;
      } else {
        // Neither date is valid, treat them as equal
        return 0;
      }
    };
  };

  const [count, setCount] = useState("50");
  const [totalcount, setTotalCount] = useState("0");
  const PageCount = ["10", "20", "50", "100"];

  const items = PageCount.map((page) => ({
    key: page,
    label: (
      <div
        style={{ width: "100%" }}
        className="pageCount"
        onClick={() => setCount(page)}
      >
        {page}
      </div>
    ),
  }));
  // Handle removing a filter
  // Handle removing a filter
  const removeThisFilter = (key) => {
    setFilteredData((prev) => {
      const updatedFilters = { ...prev, [key]: "" }; // Clear the filter
      console.log(updatedFilters); // This will log the updated state
      return updatedFilters;
    });
  };

  // Handle input change with regex check
  const handleInputChange = (e) => {
    const { value } = e.target;
    const pattern = /^[A-Za-z0-9\s]*$/;

    if (pattern.test(value)) {
      // Normalize spaces to a single space
      const normalizedValue = value.replace(/\s+/g, " ").trim();

      // Update filteredData for specific keys based on user input
      setSeaarchData(normalizedValue);
    }
  };

  // JSX rendering
  return (
    <section>
      <div className="container1">
        <div style={{ paddingBottom: rem(4) }}>
          <SalesHeader
            setFilters={setFilters}
            filters={filters}
            title={title}
          />
        </div>
        <div className="container-head df">
          <p className="title">{title}</p>
          <div className="total-count-box">{totalcount}</div>
        </div>
        <div className="container-head2 df">
          <SizeChanger count={count} items={items} />
          <div className="df" style={{ gap: rem(16) }}>
            <div className="user-search df">
              <img src={searchIcon} alt="icon" />
              <input
                // type="text"
                placeholder="Search..."
                value={searchData || ""}
                onChange={handleInputChange}
                name="user"
              />
            </div>
            {title !== "Email" && (
              <Popover
                trigger="click"
                open={openfilter}
                placement="bottomRight"
                onOpenChange={handleOpenChange}
                content={
                  <FilterEnquires
                    hide={hide}
                    filteredData={filteredData}
                    setFilteredData={setFilteredData}
                    title={title}
                  />
                }
              >
                <Space>
                  <span
                    className="filter-head"
                    data-tooltip-id="tooltip-arrow"
                    data-tooltip-content="Filter"
                  >
                    <img src={filterOn ? filteron : filter} alt="icon" />{" "}
                    <img src={filterOn ? greenDown : down} alt="icon" />
                  </span>
                </Space>
              </Popover>
            )}
          </div>
        </div>

        <div className="df filter-section">
          {filterOn && filteredData && (
            <div
              className="filtered-data-item df"
              style={{ paddingBottom: rem(6) }}
            >
              {Object.entries(filteredData).map(
                ([key, value]) =>
                  value && (
                    <span key={key} className="df filtered-option">
                      <span className="filtered-value">
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                        :{value || "N/A"}
                      </span>
                      <span
                        onClick={() => removeThisFilter(key)}
                        style={{ cursor: "pointer" }}
                      >
                        <CrossIcon />
                      </span>
                    </span>
                  )
              )}
            </div>
          )}
        </div>
        <UserDataTable
          handleOpen={handleOpen}
          users={users}
          filteredData={filteredData}
          loading={loading}
          renderEmptyOrValue={renderEmptyOrValue}
          DateSorter={DateSorter}
          defaultPageSize={count}
          totalCount={setTotalCount}
          searchData={searchData}
        />
        {open &&
          selectedUser &&
          (AwaitingOrder || OrderPlaced ? (
            <EditPopup1
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              handleOrder={handleOrder}
              handleUpdateToast={handleUpdateToast}
              AwaitingOrder={AwaitingOrder}
              reports={reports}
            />
          ) : emailE ? (
            <EmailPopup
              open={open}
              user={selectedUser}
              handleClose={handleClose}
            />
          ) : (
            <EditPopup
              open={open}
              user={selectedUser}
              handleClose={handleClose}
              updatingFun={updateBtn}
              handleUpdateToast={handleUpdateToast}
              AwaitingQuotes={AwaitingQuotes}
              handleOrder={handleOrder}
              AwaitingOrder={AwaitingOrder}
              reports={reports}
            />
          ))}
      </div>
      <SnackBar
        message={errors}
        severity={"error"}
        handleClose={handletoast}
        Open={toast.error}
      />
      <SnackBar
        message={errors}
        severity={"success"}
        handleClose={handletoast}
        Open={toast.delete}
      />
    </section>
  );
};

export default SalesTracker;
