import React from "react";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import { useNavigate } from "react-router-dom";

export default function QuizQuestions() {
  const navigate = useNavigate();

  const clickhandler = () => {
    navigate("/ranking");
  };

  return (
    <div className="quiz-questions">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
      </div>
      <div className="time_left">
        <p className="timeLeft">Time Left</p>
        <p className="timeBar">30 Minutes</p>
      </div>
      <p className="ques_head">Welding Quiz 1 Questions</p>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="mcq_ques">
        <div className="questioning_head">
          1. Why shouldn't you wear naylon clothing when welding?
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">A. Because you will get too sweaty</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">B. It is fine to wear.</p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            C. Because static electricity can build up cause shocks.
          </p>
        </div>
        <div className="questioning">
          <input type="radio" />
          <p className="points">
            D. Because it can catch fire easily and melt to your skin.
          </p>
        </div>
      </div>
      <div className="btn_quiz">
        <button className="quizBtn" onClick={clickhandler}>
          Submit & Show Answer
        </button>
      </div>
    </div>
  );
}
