// Login.jsx
import React, { useState, useEffect } from "react";
import _nonAuthHttp from "../../../Utils/Api/_nonAuthHttp";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import spiraLogo from "./../../../Assets/LoginPage/spira 1.svg";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import SnackBar from "../../../Components/SnackBar";
import FaEye from "../../../Components/Svg/FaEye";
import FaEyeSlash from "../../../Components/Svg/FaEyeSlash";
import { useDispatch } from "react-redux";
import { setUserdata } from "../../../Redux/Reducers";

// Main Login Component
export default function Login() {
  const [username, setUsername] = useState(
    localStorage.getItem("emailCredential") || ""
  );
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errors, setErrors] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(
    localStorage.getItem("emailCredential") || ""
  );

  const dispatch = useDispatch();
  // useState end
  useEffect(() => {
    dispatch(setUserdata(""));
  }, [dispatch]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to close the SnackBar
  const handleClose = () => {
    setOpen(false);
  };

  const handleUserName = (event) => {
    const value = event.target.value;

    // Remove any spaces from the input value
    const sanitizedValue = value
      .replace(/\s/g, "")
      .replace(/([,.@])\1+/g, "$1")
      .replace(/[^a-z0-9.@ ]/g, "");

    setUsername(sanitizedValue);
    setChecked(false);
  };

  const handlePassword = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\s/g, "");
    setPassword(sanitizedValue);
  };

  // Function to validate login input
  const validateLoginInput = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      return "Please fill out the fields";
    }
    return null;
  };

  // Function to handle the login process
  const handleLogin = async (e) => {
    setloading(true);
    e.preventDefault();

    // Validate login input
    const inputError = validateLoginInput(username, password);

    if (inputError) {
      setOpen(true);
      setErrors(inputError);
      setloading(false);
      return;
    }

    try {
      // Make a login request using _nonAuthHttp
      const res = await _nonAuthHttp.post("/Login", {
        email: username.toLowerCase(),
        password: password,
      });

      if (res.data.error) {
        // Handle login error
        setErrors(res.data.error);
      } else {
        // Store user data in sessionStorage on successful login
        sessionStorage.setItem("SpirajwtToken", res.data.output.access_token);
        dispatch(setUserdata({ spiraToken: res.data.output.access_token }));

        // Navigate to the home page
        navigate("/SpiraAI/Dashboard");
      }
      setPassword("");
      setUsername("");
    } catch (error) {
      // Handle login error exceptions
      setOpen(true);

      if (error.response && error.response.status === 500) {
        setErrors("Internal server error ");
      } else if (error.response && error.response.status === 400) {
        setErrors("Login failed: Incorrect Username or Password provided.");
      } else if (error.response && error.response.status === 401) {
        setErrors("Login failed: Incorrect Username or Password provided");
      } else {
        setErrors(error.message);

        console.error("Error during login:", error);
      }
    } finally {
      setloading(false);
    }
  };

  const checkHandler = () => {
    setChecked(!checked);
    if (!checked) {
      localStorage.setItem("emailCredential", username);
    } else {
      localStorage.removeItem("emailCredential");
    }
  };

  // Login component JSX
  return (
    <div className="newLogin flexBox login">
      {/* <div className="left flexBox">
        <img src={LoginImage} alt="CompanyLogo"></img>
      </div> */}
      <div className="login-logo login-title">
        <img src={spiraLogo} alt="img" />
      </div>
      <div className={`right flexBox`}>
        <div className={`login-card `}>
          <form className="login-card-form">
            <h1>Empowering Connections: Login to Spira Power AI</h1>

            <label>Spira Email</label>

            <input
              type="text"
              // name="username"
              id="spira username"
              placeholder="johndoe@spirapower.com"
              value={username}
              onChange={(event) => {
                handleUserName(event);
              }}
              className="txtBox"
              readOnly={loading}
            />

            <label>Password</label>
            <div className="password-input txtBox">
              <input
                type={showPassword ? "text" : "password"}
                // name="spira-login-password"
                id="password"
                placeholder="*********"
                value={password}
                onChange={(event) => handlePassword(event)}
                className=""
                readOnly={loading}
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {
              // SnackBar component for displaying errors
              <SnackBar
                message={errors}
                severity={"error"}
                handleClose={handleClose}
                Open={open}
              />
            }

            <div className="forgot-pass" to="/EmailVerify">
              <div className="remember-check df">
                <label className="container-check">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={checkHandler}
                  />
                  <span className="checkmark"></span>
                </label>

                <label>Remember me</label>
              </div>
              <span>
                <Link className="forget-pass" to="/generate-newpassword">
                  Forgot Password?
                </Link>
              </span>
            </div>

            <div className="login-btn">
              {/* Login button component with loading state handling */}
              <Button
                disabled={loading}
                onclick={handleLogin}
                btnname={loading ? "Loging in..." : "Login"}
                width={"100%"}
                height={57}
                fs={20}
                fw={600}
                br={4}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              <div className="login-title dont-have-acc ">
                <span> As a Spira Employee!</span>
                <Link className="subtitle" to="/Register">
                  Activate Your Spira AI Account
                </Link>
              </div>
            </div>
          </form>
        </div>
        <div className="w100 foot">
          <div className="df footer">
            <p className="footer-color">
              &copy; 2024 Spira Power - All Rights Reserved.
            </p>
            <div className="df footer1">
              <Link to="#" className="footer-color">
                Privacy Policy
              </Link>
              <Link to="#" className="footer-color">
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
