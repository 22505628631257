import React, { useMemo, useEffect } from "react";
import { rem } from "../../../Components/Rem_func";
import DraggableTable from "../../../Components/TableDrag_func";

const OPtable = ({
  handleOpen,
  users,
  filteredData,
  loading,
  renderEmptyOrValue,
  defaultPageSize,
  totalCount,
  DateSorter,
  searchData,
}) => {
  // useMemo hook to filter and format user data based on filter criteria
  const filteredUsers = useMemo(() => {
    return (users || [])
      .filter((user) => {
        const matchesFilteredData =
          (user?.reference_number || "")
            .toLowerCase()
            .includes((filteredData?.reference_number || "").toLowerCase()) &&
          (user?.sales_person_name || "")
            .toLowerCase()
            .includes((filteredData?.sales_person_name || "").toLowerCase()) &&
          (user?.client_person_name || "")
            .toLowerCase()
            .includes((filteredData?.client_person_name || "").toLowerCase()) &&
          (user?.client_subject || "")
            .toLowerCase()
            .includes((filteredData?.client_subject || "").toLowerCase()) &&
          (filteredData.order_date_from
            ? new Date(user?.order_date_time?.split(" ")[0]) >=
              new Date(filteredData?.order_date_from)
            : true) &&
          (filteredData.order_date_to
            ? new Date(user?.order_date_time?.split(" ")[0]) <=
              new Date(filteredData?.order_date_to)
            : true) &&
          (filteredData?.max_quote_value
            ? user?.currency_value
              ? Number(filteredData?.max_quote_value) >=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.min_quote_value
            ? user?.currency_value
              ? Number(filteredData?.min_quote_value) <=
                Number(user?.currency_value?.replace(/,/g, ""))
              : false
            : true) &&
          (filteredData?.max_order_value
            ? user?.order_value
              ? Number(filteredData?.max_order_value) >=
                Number(user?.order_value?.replace(/,/g, "") || 0)
              : false
            : true) &&
          (filteredData?.min_order_value
            ? user?.order_value
              ? Number(filteredData?.min_order_value) <=
                Number(user?.order_value?.replace(/,/g, ""))
              : false
            : true);
        const matchesSearchData = searchData
          ? (user?.reference_number || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_person_name || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_subject || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.sales_mail || "")
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (user?.client_email || "")
              .toLowerCase()
              .includes(searchData.toLowerCase())
          : true;

        return matchesFilteredData && matchesSearchData;
      })
      .map((user) => {
        // Highlight search matches in relevant fields
        const matchReferenceNumber =
          searchData &&
          user.reference_number
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchSalesPersonName =
          searchData &&
          user.sales_person_name
            ?.toLowerCase()
            .includes(searchData.toLowerCase());

        const matchClientEmail =
          searchData &&
          user.client_email?.toLowerCase().includes(searchData.toLowerCase());

        return {
          ...user, // Return all original user data
          reference_number_class: matchReferenceNumber
            ? "highlighted-text"
            : "",
          sales_person_name_class: matchSalesPersonName
            ? "highlighted-text"
            : "",
          client_email_class: matchClientEmail ? "highlighted-text" : "",
        };
      });
  }, [users, filteredData, searchData]);

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "ACK Number",
      dataIndex: "reference_number",
      key: "reference_number",
      width: rem(124),
      className: "",
      sorter: (a, b) =>
        (a.reference_number || "").localeCompare(b.reference_number || ""),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.substring(4),
          "",
          record.reference_number_class
        ),
    },
    {
      title: "Sales Team Member",
      dataIndex: "sales_person_name",
      key: "sales_person_name",
      className: "",
      sorter: (a, b) =>
        (a.sales_person_name || "").localeCompare(b.sales_person_name || ""),
      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.sales_person_name_class
        ),
      width: rem(134),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: rem(134),
      render: (text, record) => {
        return <span className="op status">Order Placed</span>;
      },
      className: "",
    },
    {
      title: "Quote Value",
      dataIndex: "currency_value",
      key: "currency_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.currency_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.currency_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },

    {
      title: "Order Value",
      dataIndex: "order_value",
      key: "order_value",
      sorter: (a, b) => {
        // Ensure that both 'a.order_value' and 'b.order_value' are clean numeric strings
        const cleanValueA = a.order_value?.replace(/[^0-9.-]+/g, ""); // Remove all non-numeric characters except period (.) and minus (-)
        const cleanValueB = b.order_value?.replace(/[^0-9.-]+/g, "");

        // Parse the cleaned values as floats
        const valueA = parseFloat(cleanValueA) || 0;
        const valueB = parseFloat(cleanValueB) || 0;

        // Compare the two numerical values
        return valueA - valueB;
      },
      width: rem(134),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => (a.currency || "").localeCompare(b.currency || ""),
      width: rem(84),
      render: (text) => renderEmptyOrValue(text),
    },
    {
      title: "Buyer Email",
      dataIndex: "client_email",
      key: "client_email",
      width: rem(134),
      className: "tl",

      render: (text, record) =>
        renderEmptyOrValue(
          text?.toLowerCase(),
          true,
          record.client_email_class
        ),
      sorter: (a, b) =>
        (a.client_email || "").localeCompare(b.client_email || ""),
    },
    {
      title: `Order Date Time`,
      dataIndex: "order_date_time",
      key: "order_date_time",
      sorter: DateSorter("order_date_time"),
      className: "noPadding",
      render: (text) => {
        // Append "00:00:00" if the time part is missing
        if (text && text.split(" ").length === 1) {
          text += " 00:00:00";
        }
        return (
          <span className="df justify-center">
            <span
              className="df flexColumn mAuto"
              style={{ alignItems: "baseline" }}
            >
              {renderEmptyOrValue(text, "", "", "date")}

              {renderEmptyOrValue(text, "", "", "time")}
            </span>
          </span>
        );
      },
      width: rem(114),
    },
    {
      title: "Order Closure Days",
      dataIndex: "client_email_time",
      key: "client_email_time",
      render: (text, record) => {
        const orderDate = record.order_date_time
          ? new Date(record.order_date_time)
          : null;
        const clientEmail = record.client_email_time
          ? new Date(record.client_email_time)
          : null;

        const orderClosureDays =
          orderDate && clientEmail
            ? Math.floor((orderDate - clientEmail) / (1000 * 60 * 60 * 24))
            : null;
        return (
          <span>
            {orderClosureDays !== null ? (
              `${orderClosureDays} days`
            ) : (
              <span className="empty">DataNR</span>
            )}
          </span>
        );
      },
      width: rem(134),
    },
  ];

  useEffect(() => {
    totalCount(filteredUsers.length); // Set total count directly
  }, [filteredUsers, totalCount]);
  return (
    <DraggableTable
      handleOpen={handleOpen}
      filteredUsers={filteredUsers}
      loading={loading}
      baseColumns={columns}
      drag={true}
      defaultPageSize={defaultPageSize}
    />
  );
};

export default OPtable;
