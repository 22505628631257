import React, { useState, useEffect } from "react";
import "./QuizMobile.scss";
import logoImg from "../../Assets/QuizMobile/spiraLogo1.svg";
import Button from "../../Components/Button/Button";
import google_img from "../../Assets/QuizMobile/flat-color-icons_google.svg";
import apple_img from "../../Assets/QuizMobile/Apple.svg";
import { useNavigate } from "react-router-dom";
import { rem } from "../../Components/Rem_func";
import _nonAuthHttp from "../../Utils/Api/_nonAuthHttp";
export default function QuizAdmin() {
  const [formData, setFormData] = useState({
    name: "w",
    email: "w@gmail.com ",
    phone_number: "222--9820",
  });
  const navigate = useNavigate();
  // const handleQuiz = () => {
  //   navigate("/start-quiz");
  // };
  const fetchData = async () => {
    try {
      const res = await _nonAuthHttp.post("/quiz/participants", formData);
      console.log(res.data);
      navigate("/start-quiz");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-quiz-main_container">
      <div className="logo-img">
        <img src={logoImg} alt="error" />
      </div>
      <div className="main">
        <div className="inp-section">
          <label className="label_name" for="name">
            Name
          </label>
          <div className="inp">
            <input type="text" className="inp" placeholder="Enter your name" />
          </div>
        </div>
        <div className="inp-section">
          <label className="label_name" for="email">
            Email ID
          </label>
          <div className="inp">
            <input type="text" placeholder="Enter your email" />
          </div>
        </div>
        <div className="inp-section">
          <label className="label_name" for="phone">
            Phone Number
          </label>
          <div className="inp">
            <input type="text" placeholder="Enter your phone number" />
          </div>
        </div>
        <div className="btn_quiz">
          <button className="quizBtn" onClick={fetchData}>
            Start Quiz
          </button>
        </div>
        <div className="or_div">
          <p className="line1"></p>
          <div className="or">Or</div>
          <p className="line1"></p>
        </div>
        <div className="social-media-link">
          <div>
            <img src={google_img} alt="error" />
          </div>
          <div>
            <img src={apple_img} alt="error" />
          </div>
        </div>

        <div className="or_div">
          <p className="line1"></p>
          <div className="or">Or</div>
          <p className="line1"></p>
        </div>

        <div className="card_detail">
          <button className="quizBtn">Provide Business Card Details</button>
        </div>
      </div>
    </div>
  );
}
